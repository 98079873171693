import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store/index";
import BackofficeService from "../services/backoffice.service";

const publicPages = ["/login", "/forgotten"];
const routes = [
  { path: "/", redirect: { name: "accueil" } },
  { path: "/accueil", name: "accueil", component: () => import(/* webpackChunkName: "accueil" */ "../views/StrapiTableauView.vue"), },
  { path: "/login", name: "login", component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue"), },
  { path: "/logout", name: "logout", component: () => import(/* webpackChunkName: "logout" */ "../views/LogoutView.vue"), },
  { path: "/forgotten", name: "forgotten", component: () => import(/* webpackChunkName: "forgotten" */ "../views/ForgottenPasswordView.vue"), },
  // MODULE UTILISATEUR
  { path: "/changement-mot-de-passe", name: "changement-mot-de-passe", component: () => import(/* webpackChunkName: "changement-mot-de-passe" */ "../views/utilisateur/ChangePasswordView.vue"), },
  { path: "/exploitations", name: "exploitations", component: () => import(/* webpackChunkName: "exploitations" */ "../views/utilisateur/ExploitationsView.vue"), },
  { path: "/compte", name: "compte", component: () => import(/* webpackChunkName: "compte" */ "../views/utilisateur/AccountView.vue"), },
  // MODULE COOPERATIVE
  { path: "/cooperative/page/:id", name: "page", component: () => import(/* webpackChunkName: "page" */ "../views/cooperative/StrapiPageView.vue"), },
  { path: "/cooperative/actualite/:id", name: "actualite", component: () => import(/* webpackChunkName: "actualite" */ "../views/cooperative/StrapiActualiteView.vue"), },
  { path: "/cooperative/evenement/:id", name: "evenement", component: () => import(/* webpackChunkName: "evenement" */ "../views/cooperative/StrapiEvenementView.vue"), },
  { path: "/cooperative/assemblee-generale/:id", name: "assemblee-generale", component: () => import(/* webpackChunkName: "assemblee-generale" */ "../views/cooperative/GestionAssembleeGeneraleView.vue"), },
  // MODULE DECLARATIONS
  {
    path: "/recolte/:codeRecolte", name: "recolte", component: () => import(/* webpackChunkName: "recoltes" */ "../views/recoltes/RecoltesMainView.vue"),
    children: [
      {
        path: "parcelles", name: "parcelles", component: () => import(/* webpackChunkName: "parcelles" */ "../views/recoltes/ParcellesView.vue"),
        meta: { tabbable: true, title: "Parcelles", icon: "pin-map", customIcon: "ParcellesTabIcon", },
      },
      {
        path: "emblavements", name: "emblavements", component: () => import(/* webpackChunkName: "emblavements" */ "../views/recoltes/EmblavementsView.vue"),
        meta: { tabbable: true, title: "Emblavements", icon: "water", customIcon: "EmblavementsTabIcon", },
      },
      {
        path: "autoconsommation", name: "autoconsommation", component: () => import(/* webpackChunkName: "autoconsommation" */ "../views/recoltes/AutoconsoView.vue"),
        meta: { tabbable: true, title: "Autoconsommation", icon: "arrow-counterclockwise", customIcon: "AutoconsoTabIcon", },
      },
      {
        path: "enlevements", name: "enlevements", component: () => import(/* webpackChunkName: "enlevements" */ "../views/recoltes/EnlevementsView.vue"),
        meta: { tabbable: true, title: "Enlèvements", icon: "truck", },
      },
      {
        path: "moisson", name: "moisson", component: () => import(/* webpackChunkName: "moisson" */ "../views/recoltes/MoissonView.vue"),
        meta: { tabbable: true, title: "Récolte", icon: "minecart-loaded", customIcon: "RecolteTabIcon", },
      },
    ],
  },
  // MODULE APPRO
  { path: "/appro/catalogues", name: "catalogues", component: () => import(/* webpackChunkName: "catalogues" */ "../views/appro/StrapiCataloguesView.vue"), },
  { path: "/appro/catalogue/:id/listes-produits", name: "listes-produits", component: () => import(/* webpackChunkName: "listes-produits" */ "../views/appro/ListesProduitsView.vue"), },
  { path: "/appro/catalogue/:id/commande/:idCommande?", name: "commande-appro", component: () => import(/* webpackChunkName: "commande-appro" */ "../views/appro/CommandeApproView.vue"), },
  { path: "/appro/commandes-extranet", name: "commandes-appro-extranet", component: () => import(/* webpackChunkName: "commandes-appro-extranet" */ "../views/appro/CommandesApproView.vue"), },
  { path: "/appro/bons-commande", name: "bons-commande-appro", component: () => import(/* webpackChunkName: "bons-commande-appro" */ "../views/appro/BonsCommandeApproView.vue"), },
  { path: "/appro/bons-livraison", name: "bons-livraison-appro", component: () => import(/* webpackChunkName: "bons-livraison-appro" */ "../views/appro/BonsLivraisonApproView.vue"), },
  // MODULE COLLECTE
  { path: "/collecte/reglements-campagne", name: "reglements-campagne", component: () => import(/* webpackChunkName: "reglements-campagne" */ "../views/collecte/StrapiReglementsCampagneView.vue"), },
  { path: "/collecte/analyses-moisson", name: "analyses-moisson", component: () => import(/* webpackChunkName: "analyses-moisson" */ "../views/collecte/AnalysesMoissonView.vue"), },
  { path: "/collecte/bons-apport", name: "bons-apport", component: () => import(/* webpackChunkName: "bons-apport" */ "../views/collecte/BonsApportView.vue"), },
  { path: "/collecte/contrats-apport", name: "contrats-apport", component: () => import(/* webpackChunkName: "contrats-apport" */ "../views/collecte/ContratsApportView.vue"), },
  { path: "/collecte/prix-fin-campagne", name: "prix-fin-campagne", component: () => import(/* webpackChunkName: "prix-fin-campagne" */ "../views/collecte/PrixFinCampagneView.vue"), },
  // MODULE ADMINISTRATIF
  { path: "/administratif/certificats", name: "certificats", component: () => import(/* webpackChunkName: "certificats" */ "../views/administratif/CertificatsView.vue"), },
  { path: "/administratif/releve-compte", name: "releve-compte", component: () => import(/* webpackChunkName: "releve-compte" */ "../views/administratif/ReleveCompteView.vue"), },
  { path: "/administratif/situation-capsoc", name: "situation-capsoc", component: () => import(/* webpackChunkName: "situation-capsoc" */ "../views/administratif/SituationCapsocView.vue"), },
  // MODULE COMMUNICATION
  { path: "/communication/assistant/:idTemplate?", name: "com-assistant", component: () => import(/* webpackChunkName: "com-assistant" */ "../views/communication/AssistantView.vue"), },
  { path: "/communication/templates", name: "com-templates", component: () => import(/* webpackChunkName: "com-templates" */ "../views/communication/TemplatesView.vue"), },
  { path: "/communication/historique", name: "com-historique", component: () => import(/* webpackChunkName: "com-historique" */ "../views/communication/HistoriqueView.vue"), },
  // MODULE REFERENTIEL
  {
    path: "/referentiel", name: "referentiel", component: () => import(/* webpackChunkName: "referentiel" */ "../views/referentiel/ReferentielMainView.vue"),
    children: [
      {
        path: "tiers", name: "tiers", component: () => import(/* webpackChunkName: "tiers" */ "../views/referentiel/ReferentielTiersView.vue"),
        meta: { tabbable: true, title: "Tiers", icon: "person-check", },
      },
      {
        path: "especes", name: "especes", component: () => import(/* webpackChunkName: "especes" */ "../views/referentiel/ReferentielEspeceView.vue"),
        meta: { tabbable: true, title: "Espèces", icon: "list-check", },
      },
      {
        path: "varietes", name: "varietes", component: () => import(/* webpackChunkName: "varietes" */ "../views/referentiel/ReferentielVarieteView.vue"),
        meta: { tabbable: true, title: "Variétés", icon: "list-check", },
      },
      {
        path: "produits-cereale", name: "produits-cereale", component: () => import(/* webpackChunkName: "produits-cereale" */ "../views/referentiel/ReferentielProduitCerealeView.vue"),
        meta: { tabbable: true, title: "Produits céréale", icon: "box-seam", },
      },
      {
        path: "produits-appro", name: "produits-appro", component: () => import(/* webpackChunkName: "produits-appro" */ "../views/referentiel/ReferentielProduitApproView.vue"),
        meta: { tabbable: true, title: "Produits appro", icon: "box-seam", },
      },
    ],
  },
  // MODULE BACKOFFICE
  {
    path: "/backoffice", name: "backoffice", component: () => import(/* webpackChunkName: "backoffice" */ "../views/backoffice/BackofficeMainView.vue"),
    children: [
      {
        path: "utilisateurs", name: "utilisateurs", component: () => import(/* webpackChunkName: "utilisateurs" */ "../views/backoffice/GestionUtilisateursView.vue"),
        meta: { tabbable: true, title: "Utilisateurs", icon: "people", access: (store) => store.getters['auth/hasAccesBackofficeComptes'], },
      },
      {
        path: "mails", name: "mails", component: () => import(/* webpackChunkName: "mails" */ "../views/backoffice/SuiviMailsView.vue"),
        meta: { tabbable: true, title: "Mails envoyés", icon: "envelope", access: (store) => store.getters['auth/hasAccesBackofficeMails'], },
      },
      {
        path: "statistiques", name: "statistiques", component: () => import(/* webpackChunkName: "statistiques" */ "../views/backoffice/StatistiquesView.vue"),
        meta: { tabbable: true, title: "Statistiques", icon: "graph-up", access: (store) => store.getters['auth/hasAccesBackofficeStatistiques'], },
      },
      {
        path: "batchs", name: "batchs", component: () => import(/* webpackChunkName: "batchs" */ "../views/backoffice/GestionTraitementsView.vue"),
        meta: { tabbable: true, title: "Traitements", icon: "terminal", access: (store) => store.getters['auth/hasAccesBackofficeTraitements'], },
      },
      {
        path: "parametres", name: "parametres", component: () => import(/* webpackChunkName: "parametres" */ "../views/backoffice/GestionParametresView.vue"),
        meta: { tabbable: true, title: "Paramètres", icon: "tools", access: (store) => store.getters['auth/hasAccesBackofficeParametres'], },
      },
    ],
  },
  // PIED DE PAGE
  { path: "/changelog", name: "changelog", component: () => import(/* webpackChunkName: "changelog" */ "../views/footer/StrapiChangelogView.vue"), },
  { path: "/foire-aux-questions", name: "faq", component: () => import(/* webpackChunkName: "foire-aux-questions" */ "../views/footer/StrapiFaqView.vue"), },
  { path: "/mentions-legales", name: "mentions", component: () => import(/* webpackChunkName: "mentions-legales" */ "../views/footer/StrapiMentionsLegalesView.vue"), },
  { path: "/politique-confidentialite", name: "confidentialite", component: () => import(/* webpackChunkName: "confidentialite" */ "../views/footer/StrapiConfidentialiteView.vue"), },
  { path: "/ecoconception", name: "ecoconception", component: () => import(/* webpackChunkName: "ecoconception" */ "../views/footer/StrapiEcoconceptionView.vue"), },
  { path: "/accessibilite", name: "accessibilite", component: () => import(/* webpackChunkName: "accessibilite" */ "../views/footer/StrapiAccessibiliteView.vue"), },
];

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  // Si l'utilisateur est connecté, on trace la navigation
  const loggedIn = store.getters["auth/loggedIn"];
  if (loggedIn) {
    BackofficeService.logNavigation({
      fromRoute: from?.path,
      toRoute: to.path,
      breakpoint: store.state.currentBreakpoint,
    });
    next();
    return;
  }

  // Sinon on interdit l'accès aux routes privées
  // Et on autorise l'accès aux routes publiques
  const authRequired = !publicPages.includes(to.path);
  next(authRequired ? "/login" : undefined);
});

export default router;
