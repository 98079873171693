const PrivilegeApplication = {

  /**
   * Accès aux mails envoyés
   */
  BACKOFFICE_MAILS: "BACKOFFICE_MAILS",

  /**
   * Accès aux statistiques
   */
  BACKOFFICE_STATISTIQUES: "BACKOFFICE_STATISTIQUES",

  /**
   * Accès aux traitements batch
   */
  BACKOFFICE_TRAITEMENTS: "BACKOFFICE_TRAITEMENTS",

  /**
   * Accès aux paramètres
   */
  BACKOFFICE_PARAMETRES: "BACKOFFICE_PARAMETRES",

  /**
   * Modification des droits d'accès
   */
  BACKOFFICE_DROITS: "BACKOFFICE_DROITS",

};

const PrivilegeSociete = {

  /**
   * Accès au portefeuille des tiers de la société
   */
  BASE_PORTEFEUILLE: "BASE_PORTEFEUILLE",

  /**
   * Accès aux actualités et évènements de la société
   */
  STRAPI_ACTUALITES: "STRAPI_ACTUALITES",

  /**
   * Accès aux contacts/annuaire de la société
   */
  STRAPI_CONTACTS: "STRAPI_CONTACTS",

  /**
   * Accès aux lettres techniques de la société
   */
  STRAPI_LETTRES: "STRAPI_LETTRES",

  /**
   * Accès aux contenus Strapi non publiés
   */
  STRAPI_BROUILLONS: "STRAPI_BROUILLONS",

  /**
   * Accès aux catalogues de la société
   */
  APPRO_CATALOGUE: "APPRO_CATALOGUE",

  /**
   * Accès aux outils de gestion de catalogue
   */
  APPRO_GESTION: "APPRO_GESTION",

  /**
   * Accès à l'outil de transfert des déclarations
   */
  DECLARATIONS_TRANSFERT: "DECLARATIONS_TRANSFERT",

  /**
   * Accès au tableau de bord technicien
   */
  DECLARATIONS_SYNTHESE: "DECLARATIONS_SYNTHESE",

  /**
   * Accès aux outils d'organisation de l'AG
   */
  ADMINISTRATIF_AG_GESTION: "ADMINISTRATIF_AG_GESTION",

  /**
   * Accès au module communication
   */
  COMMUNICATION_MODULE: "COMMUNICATION_MODULE",

  /**
   * Accès au module référentiel
   */
  REFERENTIEL_MODULE: "REFERENTIEL_MODULE",

  /**
   * Accès aux comptes utilisateur
   */
  BACKOFFICE_COMPTES: "BACKOFFICE_COMPTES",

};

const PrivilegeExploitation = {

  /**
   * Accès lecture seule aux données personnelles
   */
  BASE_DONNEES_PERSONNELLES: "BASE_DONNEES_PERSONNELLES",

  /**
   * Accès lecture seule au module appro
   */
  APPRO_MODULE: "APPRO_MODULE",

  /**
   * Accès à la commande en ligne
   */
  APPRO_COMMANDE: "APPRO_COMMANDE",

  /**
   * Accès lecture/écriture au module déclarations
   */
  DECLARATIONS_MODULE: "DECLARATIONS_MODULE",

  /**
   * Accès au module collecte
   */
  COLLECTE_MODULE: "COLLECTE_MODULE",

  /**
   * Accès aux analyses d'échantillons moisson
   */
  COLLECTE_ECHANTILLONS: "COLLECTE_ECHANTILLONS",

  /**
   * Accès aux récapitulatifs Egalim par campagne
   */
  COLLECTE_EGALIM: "COLLECTE_EGALIM",

  /**
   * Accès au dépôt de certificat Bio
   */
  ADMINISTRATIF_CERTIFICATS: "ADMINISTRATIF_CERTIFICATS",

  /**
   * Accès à l'écran récapitulatif du capital social
   */
  ADMINISTRATIF_CAPSOC: "ADMINISTRATIF_CAPSOC",

  /**
   * Accès aux informations sur les AG + réponse
   */
  ADMINISTRATIF_AG_ADHERENT: "ADMINISTRATIF_AG_ADHERENT",

  /**
   * Accès au relevé de compte du tiers
   */
  ADMINISTRATIF_RELEVE_COMPTE: "ADMINISTRATIF_RELEVE_COMPTE",

};

export {
  PrivilegeApplication,
  PrivilegeSociete,
  PrivilegeExploitation,
};
