import { apiPwfb } from "../api-pwfb";
import UtilsService from "./utils.service";

const TypeCarac = {
  INFO_LIBRE: { value: "INFO_LIBRE", text: "Information libre (à saisir)", sources: [], destinations: [], },
  INFO_AUTO: { value: "INFO_AUTO", text: "Information en provenance de Kerhis", sources: ["AB_NT", "DESCRIPTION", "PRIX"], destinations: [], },
  SAISIE_QUANTITE: { value: "SAISIE_QUANTITE", text: "Champ de commande (conditionnement)", sources: [], destinations: [], },
  SAISIE_AUTRE: { value: "SAISIE_AUTRE", text: "Champ de saisie paramétrable", sources: [], destinations: ["DATE_SEMIS"], },
};

const StatutCommande = {
  COMMANDABLE: { name: "COMMANDABLE", ordinal: 0, icon: "info-circle-fill", variant: "success" },
  SOUS_CONDITION: { name: "SOUS_CONDITION", ordinal: 1, icon: "exclamation-circle-fill", variant: "warning" },
  RUPTURE: { name: "RUPTURE", ordinal: 2, icon: "x-circle-fill", variant: "danger" },
  ARRETE: { name: "ARRETE", ordinal: 3, icon: "x-circle-fill", variant: "dark" },
};

const EtapeCommande = UtilsService.generateEnumClass([
  { name: "EN_COURS", libelle: "Brouillon", tooltip: null, },
  { name: "VALIDEE", libelle: "Validée", tooltip: "Commande en attente d'envoi, non modifiable", },
  { name: "TRANSMISE", libelle: "Transmise", tooltip: "Commande reçue par votre coopérative", },
  { name: "TRAITEE", libelle: "Traitée", tooltip: "Commande validée par votre coopérative", },
  { name: "ANNULEE", libelle: "Annulée", tooltip: "Commande annulée par votre coopérative", },
]);

const emptyCaracDefinition = function () {
  return {
    idCarac: null,
    ordre: 0,
    libelle: "",
    type: null,
    champSource: null,
    champDestination: null,
    largeur: "auto",
  };
}

const emptyProduitCatalogue = function () {
  return {
    idProduit: null,
    ordre: 0,
    statut: StatutCommande.COMMANDABLE.name,
    messageStatut: "",
    caracs: {},
    conditionnements: {},
  };
}

const emptyCaracValeur = function () {
  return {
    idCarac: null,
    idCaracDefinition: null,
    valeur: "",
  };
}

const ajouterValeursCaracsVides = function (liste) {
  let caracs = liste.caracs.filter(
    (c) => c.type === TypeCarac.INFO_LIBRE.value ||
      c.type === TypeCarac.INFO_AUTO.value
  );
  liste.produits.forEach((p) => {
    caracs.forEach((c) => {
      if (!p.caracs[c.idCarac]) {
        p.caracs[c.idCarac] = {
          ...emptyCaracValeur(),
          idCaracDefinition: c.idCarac,
        };
      }
    });
  });
}

const emptyConditionnement = function () {
  return {
    idConditionnement: null,
    idCaracDefinition: null,
    codeProduit: null,
    commandable: true,
  };
}

const ajouterConditionnementsVides = function (liste) {
  // Créer les conditionnements vides manquants
  let caracs = liste.caracs.filter(
    (c) => c.type === TypeCarac.SAISIE_QUANTITE.value
  );
  liste.produits.forEach((p) => {
    caracs.forEach((c) => {
      if (!p.conditionnements[c.idCarac]) {
        p.conditionnements[c.idCarac] = {
          ...emptyConditionnement(),
          idCaracDefinition: c.idCarac,
        };
      }
    });
  });
}

const emptyCommandeExtranet = function () {
  return {
    idEntete: null,
    idCatalogue: null,
    codeDepot: null,
    nomUsage: null,
    adresse1: null,
    adresse2: null,
    idCommune: null,
    codeTiersFacture: null,
    etape: EtapeCommande.EN_COURS.name,
    commentaire: null,
    lignes: [],
  };
}

const emptyLigneCommandeExtranet = function () {
  return {
    idLigne: null,
    numeroLigne: 0,
    idConditionnement: null,
    dateSemis: null,
    quantiteCommandee: 0.0,
    commentaire: null,
  };
}

const getDatesSemis = function () {
  let date = new Date();
  let options = [{ value: null, text: "" }];
  while (options.length < 24) {
    date.setDate(1);
    options.push({
      value: UtilsService.dateToIsoSqlDate(date),
      text: UtilsService.getMoisLong(date.getMonth() + 1) + " (01-14)",
    });
    date.setDate(15);
    options.push({
      value: UtilsService.dateToIsoSqlDate(date),
      text: UtilsService.getMoisLong(date.getMonth() + 1) + " (15-fin)",
    });
    UtilsService.dateApplyOffset(date, 1, "Month");
  }
  return options;
}

class ApproService {

  // GESTION DES CATALOGUES

  getCatalogue(idCatalogue) {
    return apiPwfb.get(`/appro/catalogue/${idCatalogue}`);
  }
  ouvrirCommande(idCatalogue) {
    return apiPwfb.get(`/appro/catalogue/${idCatalogue}/ouvrir-commande`);
  }
  fermerCommande(idCatalogue) {
    return apiPwfb.get(`/appro/catalogue/${idCatalogue}/fermer-commande`);
  }
  getListeProduits(idListe) {
    return apiPwfb.get(`/appro/liste-produits/${idListe}`);
  }
  updateListeProduits(liste) {
    liste.produits.forEach((p) => {
      // Supprimer les valeurs de caracs vides avant envoi à l'API
      for (const key in p.caracs) {
        if (!p.caracs[key]?.valeur) {
          delete p.caracs[key];
        }
      }
      // Supprimer les conditionnements vides avant envoi à l'API
      for (const key in p.conditionnements) {
        if (!p.conditionnements[key]?.codeProduit) {
          delete p.conditionnements[key];
        }
      }
    });
    return apiPwfb.post("/appro/liste-produits/update", liste);
  }

  // GESTION DES COMMANDES

  getCommande(idCommande) {
    return apiPwfb.get(`/appro/commande/${idCommande}`);
  }
  async createUpdateCommande(commande) {
    // Supprimer les lignes vides et renuméroter les autres
    commande.lignes = commande.lignes
      .filter((l) => l.quantiteCommandee > 0)
      .map((l, i) => {
        l.numeroLigne = i + 1;
        return l;
      });

    // Si aucune ligne, supprimer la commande
    if (commande.lignes.length <= 0) {
      if (commande.idEntete) {
        await this.deleteCommande(commande.idEntete);
      }
      return;
    }

    // Sinon la créer/modifier
    return commande.idEntete
      ? apiPwfb.post("/appro/commande/update", commande)
      : apiPwfb.post("/appro/commande/create", commande);
  }
  validateCommande(idCommande) {
    return apiPwfb.get(`/appro/commande/${idCommande}/validate`);
  }
  deleteCommande(idCommande) {
    return apiPwfb.get(`/appro/commande/${idCommande}/delete`);
  }
}

const methods = {
  emptyCaracDefinition,
  emptyProduitCatalogue,
  emptyCaracValeur,
  ajouterValeursCaracsVides,
  emptyConditionnement,
  ajouterConditionnementsVides,
  emptyCommandeExtranet,
  emptyLigneCommandeExtranet,
  getDatesSemis,
};

export default new ApproService();
export { TypeCarac, StatutCommande, EtapeCommande, methods };