<template>
  <b-nav vertical>
    <b-row
      v-if="environment !== 'PROD'"
      class="m-0"
      style="background-color: orange"
    >
      <b-col>
        <b-nav-text class="w-100 text-center">
          <strong style="color: blue">
            ENVIRONNEMENT DE
            {{ environment }}
          </strong>
        </b-nav-text>
      </b-col>
    </b-row>
    <b-row class="m-0">
      <b-col>
        <b-nav-text class="w-100 text-center">
          <ExploitationMenuIcon></ExploitationMenuIcon>
          {{ exploitationCourante.raisonSociale }}
        </b-nav-text>
      </b-col>
    </b-row>
  </b-nav>
</template>

<script>
export default {
  name: "MenuHeaderComponent",
  computed: {
    environment() {
      return this.$store.state.environment.toUpperCase();
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
};
</script>
