import { PrivilegeApplication, PrivilegeSociete, PrivilegeExploitation } from "./auth.default";
import AuthService from "../services/auth.service";
import AccountService from "../services/account.service";
import UtilsService from "../services/utils.service";

export const auth = {
  namespaced: true,
  state: {
    loggedIn: JSON.parse(localStorage.getItem("auth/loggedIn")) || false,
    user: JSON.parse(localStorage.getItem("auth/user")) || null,
    accessToken: JSON.parse(localStorage.getItem("auth/accessToken")) || null,
    refreshToken: JSON.parse(localStorage.getItem("auth/refreshToken")) || null,
    error: null,
  },
  getters: {
    loggedIn: (state) =>
      state.loggedIn === true,
    societes: (state) =>
      state.user?.contacts.map((c) => c.codeSociete) || [],
    contactCourant: (state, _, rootState) => {
      const codeTiers = rootState.expl.exploitationCourante;
      return state.user?.contacts?.find((c) => c.codeTiers === codeTiers);
    },
    prenomNom: (_, getters) => {
      const contact = getters.contactCourant;
      return `${contact?.prenom ?? ""} ${contact?.nom ?? ""}`;
    },
    hasPrivilege: (_, getters) => (codePrivilege) => {
      const contact = getters.contactCourant;
      return !!contact?.privileges.find(p => p.codePrivilege === codePrivilege);
    },
    hasPrivilegeSurSocieteCourante: (_, getters, __, rootGetters) => (codePrivilege) => {
      const contact = getters.contactCourant;
      const codeSociete = rootGetters["expl/adherentCourant"]?.codeSociete;
      return !!contact?.privileges.find(p => p.codePrivilege === codePrivilege && p.codesSociete.includes(codeSociete));
    },

    // Droits d'accès transverses
    hasAccesPortefeuille: (_, getters) => getters.hasPrivilege(PrivilegeSociete.BASE_PORTEFEUILLE),

    // Droits d'accès module COOPERATIVE
    hasAccesStrapiActualites: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.STRAPI_ACTUALITES),
    hasAccesStrapiContacts: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.STRAPI_CONTACTS),
    hasAccesStrapiLettres: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.STRAPI_LETTRES),
    hasAccesStrapiBrouillons: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.STRAPI_BROUILLONS),

    // Droits d'accès module APPRO
    hasAccesApproCatalogue: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.APPRO_CATALOGUE),
    hasAccesApproCommande: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.APPRO_COMMANDE),
    hasAccesApproGestion: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.APPRO_GESTION),
    hasAccesApproModule: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.APPRO_MODULE),

    // Droits d'accès module DECLARATIONS
    hasAccesDeclarationsTransfert: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.DECLARATIONS_TRANSFERT),
    hasAccesDeclarationsModule: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.DECLARATIONS_MODULE),

    // Droits d'accès module COLLECTE
    hasAccesCollecteEchantillons: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.COLLECTE_ECHANTILLONS),
    hasAccesCollecteEgalim: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.COLLECTE_EGALIM),
    hasAccesCollecteModule: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.COLLECTE_MODULE),

    // Droits d'accès module ADMINISTRATIF
    hasAccesAdministratifAgAdherent: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.ADMINISTRATIF_AG_ADHERENT),
    hasAccesAdministratifAgGestion: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.ADMINISTRATIF_AG_GESTION),
    hasAccesAdministratifCertificats: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.ADMINISTRATIF_CERTIFICATS),
    hasAccesAdministratifReleveCompte: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.ADMINISTRATIF_RELEVE_COMPTE),
    hasAccesAdministratifCapsoc: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeExploitation.ADMINISTRATIF_CAPSOC),
    hasAccesAdministratifModule: (_, getters) => {
      return getters.hasAccesAdministratifAgAdherent
        || getters.hasAccesAdministratifAgGestion
        || getters.hasAccesAdministratifCertificats
        || getters.hasAccesAdministratifReleveCompte
        || getters.hasAccesAdministratifCapsoc
    },

    // Droits d'accès module BACKOFFICE
    hasAccesBackofficeComptes: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.BACKOFFICE_COMPTES),
    hasAccesBackofficeMails: (_, getters) => getters.hasPrivilege(PrivilegeApplication.BACKOFFICE_MAILS),
    hasAccesBackofficeStatistiques: (_, getters) => getters.hasPrivilege(PrivilegeApplication.BACKOFFICE_STATISTIQUES),
    hasAccesBackofficeTraitements: (_, getters) => getters.hasPrivilege(PrivilegeApplication.BACKOFFICE_TRAITEMENTS),
    hasAccesBackofficeParametres: (_, getters) => getters.hasPrivilege(PrivilegeApplication.BACKOFFICE_PARAMETRES),
    hasAccesBackofficeDroits: (_, getters) => getters.hasPrivilege(PrivilegeApplication.BACKOFFICE_DROITS),
    hasAccesBackofficeModule: (_, getters) => {
      return getters.hasAccesBackofficeMails
        || getters.hasAccesBackofficeStatistiques
        || getters.hasAccesBackofficeTraitements
        || getters.hasAccesBackofficeParametres
        || getters.hasAccesBackofficeDroits
        || getters.hasAccesBackofficeComptes;
    },

    // Droits d'accès autres modules
    hasAccesCommunicationModule: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.COMMUNICATION_MODULE),
    hasAccesReferentielModule: (_, getters) => getters.hasPrivilegeSurSocieteCourante(PrivilegeSociete.REFERENTIEL_MODULE),
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      try {
        commit("setError", null);
        let response = await AuthService.login(payload);
        commit("setAccessToken", response.data.accessToken);
        commit("setRefreshToken", response.data.refreshToken);
        await dispatch("loadUserAccount");
        await dispatch("onLogin", null, { root: true });
        commit("setLoggedIn", true);
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async mount({ commit, dispatch }) {
      try {
        commit("setError", null);
        await dispatch("loadUserAccount");
        await dispatch("onMount", null, { root: true });
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async loadUserAccount({ commit }) {
      let response = await AccountService.getSelfAccount();
      commit("setUser", response.data);
    },
    async refresh({ commit, dispatch }) {
      try {
        commit("setError", null);
        commit("setAccessToken", null);
        let response = await AuthService.refresh();
        commit("setAccessToken", response.data.accessToken);
      } catch (e) {
        await dispatch("logout");
        commit("setError", UtilsService.handleError(e));
        throw e;
      }
    },
    async logout({ commit, dispatch }) {
      commit("setError", null);
      commit("setLoggedIn", false);
      commit("setUser", null);
      commit("setAccessToken", null);
      commit("setRefreshToken", null);
      await dispatch("onLogout", null, { root: true });
    },
  },
  mutations: {
    setLoggedIn(state, loggedIn) {
      localStorage.setItem("auth/loggedIn", JSON.stringify(loggedIn));
      state.loggedIn = loggedIn;
    },
    setUser(state, user) {
      localStorage.setItem("auth/user", JSON.stringify(user));
      state.user = user;
    },
    setAccessToken(state, accessToken) {
      localStorage.setItem("auth/accessToken", JSON.stringify(accessToken));
      state.accessToken = accessToken;
    },
    setRefreshToken(state, refreshToken) {
      localStorage.setItem("auth/refreshToken", JSON.stringify(refreshToken));
      state.refreshToken = refreshToken;
    },
    setError(state, error) {
      state.error = error;
    }
  },
};
