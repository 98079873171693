<template>
  <b-navbar class="d-none d-md-flex flex-column menu-desktop">
    <b-navbar-brand class="w-100 m-0 p-1 logo">
      <b-link :to="{ name: 'accueil' }">
        <b-img fluid :src="logoSociete" alt="Logo de la société"></b-img>
      </b-link>
    </b-navbar-brand>
    <MenuHeaderComponent></MenuHeaderComponent>
    <section class="w-100 contenu-scrollable" style="overflow: auto">
      <MenuCommunComponent></MenuCommunComponent>
    </section>
    <b-nav vertical class="mt-auto">
      <b-nav-item class="exploitation-link" :to="{ name: 'compte' }">
        <ExploitationCardComponent></ExploitationCardComponent>
      </b-nav-item>
    </b-nav>
  </b-navbar>
</template>

<script>
import MenuHeaderComponent from "./MenuHeaderComponent.vue";
import MenuCommunComponent from "./MenuCommunComponent.vue";
import ExploitationCardComponent from "./ExploitationCardComponent.vue";
export default {
  name: "MenuDesktopComponent",
  components: {
    MenuHeaderComponent,
    MenuCommunComponent,
    ExploitationCardComponent,
  },
  computed: {
    societe() {
      return this.$store.getters["societe"];
    },
    logoSociete() {
      return this.societe.logoBlanc ?? this.societe.logo;
    },
  },
};
</script>
