<template>
  <b-container fluid class="exploitation-card">
    <b-row class="justify-content" align-v="center">
      <b-col class="pr-0" cols="auto">
        <span class="h3">
          <AccountMenuIcon></AccountMenuIcon>
        </span>
      </b-col>
      <b-col>
        {{ prenomNom }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AccountMenuIcon from "../icons/AccountMenuIcon.vue";
export default {
  components: { AccountMenuIcon },
  name: "ExploitationCardComponent",
  computed: {
    prenomNom() {
      return this.$store.getters["auth/prenomNom"];
    },
  },
};
</script>
