import { apiPwfb, Tiers } from "../api-pwfb";

class ReferentielService {
  // SERVICES DE RECHERCHE FILTREE
  getTiers(payload) {
    return apiPwfb.post("/referentiel/tiers/search", payload)
      .then((response) => {
        response.data.content = response.data.content.map(t => new Tiers(t));
        return response;
      });
  }
  getEspeces(payload) {
    return apiPwfb.post("/referentiel/especes/search", payload);
  }
  getProduitsCereale(payload) {
    return apiPwfb.post("/referentiel/produits-cereale/search", payload);
  }
  getProduitsAppro(payload) {
    return apiPwfb.post("/referentiel/produits-appro/search", payload);
  }
  getVarietes(payload) {
    return apiPwfb.post("/referentiel/varietes/search", payload);
  }
  getCommunes(payload) {
    return apiPwfb.post("/referentiel/communes/search", payload);
  }
  // SERVICES DE LOAD AND CACHE
  getAllSocietes() {
    return apiPwfb.get("/referentiel/societes");
  }
  getAllEspeces() {
    return apiPwfb.get("/referentiel/especes");
  }
  getAllProduitsCereale() {
    return apiPwfb.get("/referentiel/produits-cereale");
  }
  getAllProduitsAppro() {
    return apiPwfb.get("/referentiel/produits-appro");
  }
  getAllVarietes() {
    return apiPwfb.get("/referentiel/varietes");
  }
  getAllModesEnlevement() {
    return apiPwfb.get("/referentiel/modes-enlevement");
  }
  getAllMotifsAutoconso() {
    return apiPwfb.get("/referentiel/motifs-autoconso");
  }
  getAllPeriodesSemis() {
    return apiPwfb.get("/referentiel/periodes-semis");
  }
  getAllPeriodesMoisson() {
    return apiPwfb.get("/referentiel/periodes-moisson");
  }
  getAllTypesTiers() {
    return apiPwfb.get("/referentiel/types-tiers");
  }
  getAllFonctions() {
    return apiPwfb.get("/referentiel/fonctions");
  }
  getAllDepartements() {
    return apiPwfb.get("/referentiel/departements");
  }
  getAllParametres() {
    return apiPwfb.get("/referentiel/parametres");
  }
  revalidate(payload) {
    return apiPwfb.post("/referentiel/revalidate", payload);
  }
  revalidateAppro(payload) {
    return apiPwfb.post("/referentiel-appro/revalidate", payload);
  }
}

export default new ReferentielService();
