import qs from "qs";
import { apiStrapi } from "../api-strapi";
import UtilsService, { SortDirection } from "./utils.service";

// Chemins de propriétés en lazy-loading
const POPULATE_EVERYTHING = [
  "societe",
  "categorielettre",
  "couverture",
  "fichier",
  "catalogue",
  "bons",
  "bonPouvoir",
  "couponReponse",
  "contenu.colonnes.contenu",
  "contenu.images",
  "gallerie.images",
  "fichiers.fichiers",
];

// SERVICE D'ACCES A L'API

class StrapiApiService {
  // TABLEAUX ET WIDGETS
  getTableaux(coop) {
    let query = qs.stringify({
      filters: {
        societe: { codetiers: { $eq: coop, }, },
      },
      populate: ["alertes.page", "contenu.page", "contenu.pageEvenements", "contenu.pageActualites", "contenu.contacts.avatar", "contenu.contacts.equipe"]
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/tableaux?${query}`);
  }
  getProchainsEvenements(coop, limite = 3) {
    // Récupérer les x prochains évènements mis en avant
    let query = qs.stringify({
      filters: {
        societe: { codetiers: { $eq: coop, }, },
        dateDebut: { $gte: UtilsService.dateToIsoSqlDate(new Date()) },
        miseEnAvant: { $eq: true, },
      },
      sort: ["dateDebut:asc", "dateFin:desc"],
      pagination: { start: 0, limit: limite, },
      populate: ["couverture"],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/evenements?${query}`);
  }
  getDernieresActualites(coop, limite = 3) {
    // Récupérer les x dernières actualités mises en avant
    let query = qs.stringify({
      filters: {
        societe: { codetiers: { $eq: coop, }, },
        miseEnAvant: { $eq: true, },
      },
      sort: ["ordre:asc", "publishedAt:desc"],
      pagination: { start: 0, limit: limite, },
      populate: ["couverture"],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/actualites?${query}`);
  }
  async getDerniersDocuments(store, coop, limite = 3) {
    // Préparer les queries pour chaque requête
    let query = qs.stringify({
      filters: {
        societe: { codetiers: { $eq: coop, }, },
      },
      sort: "publishedAt:desc",
      pagination: { start: 0, limit: limite, },
      populate: ["fichier", "catalogue", "couverture"],
    }, {
      encodeValuesOnly: true,
    });
    let queryLettres = qs.stringify({
      filters: {
        categorie: { societe: { codetiers: { $eq: coop, }, }, },
      },
      sort: "publishedAt:desc",
      pagination: { start: 0, limit: limite, },
      populate: ["fichier", "couverture", "categorie.page"],
    }, {
      encodeValuesOnly: true,
    });

    // Récupérer les x derniers documents de chaque catégorie
    let promises = [];
    if (store.getters['auth/hasAccesApproCatalogue']) {
      promises.push(this.getXDerniersCatalogues(query));
    }
    if (store.getters['auth/hasAccesStrapiLettres']) {
      promises.push(this.getXDernieresLettres(queryLettres));
    }
    if (store.getters['auth/hasAccesCollecteModule']) {
      promises.push(this.getXDernierReglementsCampagne(query));
    }

    // Extraire les x derniers documents de l'ensemble
    let result = await Promise.all(promises);
    return result
      .flatMap(a => a)
      .sort(UtilsService.sortByStringProperty("publishedAt", SortDirection.DESC))
      .slice(0, limite);
  }
  async getXDerniersCatalogues(query) {
    let result = await apiStrapi.get(`/api/catalogues?${query}`);
    return result.data.data
      .filter((c) => !!c.attributes.catalogue?.data?.attributes.url)
      .map((c) => ({
        titre: c.attributes.titre,
        publishedAt: c.attributes.publishedAt,
        image: c.attributes.couverture?.data?.attributes.formats?.small?.url,
        url: c.attributes.catalogue.data.attributes.url,
        route: { name: "catalogues" },
      }));
  }
  async getXDernieresLettres(query) {
    let result = await apiStrapi.get(`/api/lettres-techniques?${query}`)
    return result.data.data
      .filter((l) => !!l.attributes.fichier?.data?.attributes.url)
      .map((l) => ({
        titre: l.attributes.titre,
        publishedAt: l.attributes.publishedAt,
        image: l.attributes.couverture?.data?.attributes.formats?.small?.url,
        url: l.attributes.fichier.data.attributes.url,
        route: { name: "page", params: { id: l.attributes.categorie?.data?.attributes.page?.data?.id } },
      }));
  }
  async getXDernierReglementsCampagne(query) {
    let result = await apiStrapi.get(`/api/reglements-campagne?${query}`);
    return result.data.data
      .filter((r) => !!r.attributes.fichier?.data?.attributes.url)
      .map((r) => ({
        titre: r.attributes.titre ||
          `Règlement de campagne ${r.attributes.campagne}`,
        publishedAt: r.attributes.publishedAt,
        image: r.attributes.couverture?.data?.attributes.formats?.small?.url,
        url: r.attributes.fichier.data.attributes.url,
        route: { name: "reglements-campagne" },
      }));
  }
  // COLLECTIONS
  getPagesBySocietes(codesSociete) {
    return this.getCollectionBySocietes("pages", codesSociete);
  }
  getCollectionBySocietes(collection, codesSociete) {
    let filters = {};
    if (!codesSociete.includes("FERMESBIO")) {
      filters = {
        societe: { codetiers: { $in: codesSociete } }
      };
    }
    let query = qs.stringify({
      filters,
      pagination: { start: 0, limit: 1000, },
      populate: POPULATE_EVERYTHING,
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi
      .get(`/api/${collection}?${query}`);
  }
  /**
   * Récupère tous les objets d'une collection Strapi appartenant à la coop spécifiée
   * 
   * @param {*} collection métadonnées de la collection (cf. strapi.module)
   * @param {*} coop codeTiers de la société cible
   * @returns 
   */
  getCollection({ slug, filters, publicationState = "live" }, coop) {
    let query = qs.stringify({
      filters: {
        ...filters,
        societe: { codetiers: { $eq: coop, }, },
      },
      publicationState,
      pagination: { start: 0, limit: 1000, },
      populate: POPULATE_EVERYTHING,
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi
      .get(`/api/${slug}?${query}`);
  }
  // ITEMS ET SOUS-COLLECTIONS
  getContacts(idEquipe) {
    let query = qs.stringify({
      filters: {
        equipe: { id: { $eq: idEquipe, }, },
      },
      pagination: { start: 0, limit: 1000, },
      populate: ["avatar"],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/contacts?${query}`);
  }
  getContactsFromEmails(emails) {
    // FIXME Rustine un peu crade pour éviter que qs ne fasse sauter le filtre email et récupère TOUS les contacts de Strapi
    // Se produisait sur toute exploitation où aucun intervenant n'était trouvé (comptes salariés + certains adhérents)
    if (!Array.isArray(emails) || emails.length === 0) {
      emails = ["dummy@dummy.dummy"];
    }
    let query = qs.stringify({
      filters: {
        email: { $in: emails, },
      },
      pagination: { start: 0, limit: 1000, },
      populate: ["avatar", "equipe"],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/contacts?${query}`);
  }
  getEdito(url, coop) {
    let query = qs.stringify({
      filters: {
        url: { $eq: url, },
        societe: { codetiers: { $eq: coop, }, },
      },
      populate: "contenu.colonnes.contenu",
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/editos?${query}`);
  }
  getLettresTechniques(idCategorie) {
    let query = qs.stringify({
      filters: {
        categorie: { id: { $eq: idCategorie, }, },
      },
      pagination: { start: 0, limit: 1000, },
      populate: ["categorie", "couverture", "fichier"],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/lettres-techniques?${query}`);
  }
  getQuestions(idCategorie) {
    let query = qs.stringify({
      filters: {
        categorie: { id: { $eq: idCategorie, }, },
      },
      pagination: { start: 0, limit: 1000, },
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/questions?${query}`);
  }
  getCatalogue(idCatalogue) {
    let query = qs.stringify({
      populate: ["entete.colonnes.contenu", "listesProduits.entete.colonnes.contenu", "listesProduits.contenu.colonnes.contenu",],
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/catalogues/${idCatalogue}?${query}`);
  }
  getListesProduits(idCatalogue) {
    let query = qs.stringify({
      filters: {
        catalogue: { id: { $eq: idCatalogue, }, },
      },
      populate: "contenu.colonnes.contenu",
    }, {
      encodeValuesOnly: true,
    });
    return apiStrapi.get(`/api/listes-produits?${query}`);
  }
  // ASSETS
  getBaseUrl() {
    return apiStrapi.defaults.baseURL;
  }
  getAssetUrl(url) {
    return url ? `${this.getBaseUrl()}${url}` : "";
  }
  getImageUrl(fichier, format) {
    if (!fichier?.data?.id) {
      return "";
    }
    // Si le format demandé existe, on l'utilise
    if (format && fichier.data.attributes?.formats[format]?.url) {
      return this.getAssetUrl(fichier.data.attributes?.formats[format].url);
    }
    // Sinon on renvoie l'image originale
    return this.getAssetUrl(fichier.data.attributes.url);
  }
  // SINGLE TYPES
  getChangelog() {
    return apiStrapi.get(`/api/changelog`);
  }
  getMentionsLegales() {
    return apiStrapi.get(`/api/mentions-legales`);
  }
  getPolitiqueConfidentialite() {
    return apiStrapi.get(`/api/politique-confidentialite`);
  }
  getEcoconception() {
    return apiStrapi.get(`/api/ecoconception`);
  }
  getAccessibilite() {
    return apiStrapi.get(`/api/accessibilite`);
  }
}

const StrapiService = new StrapiApiService();

// Fonctions réutilisables pour les composants Strapi

/**
 * IMPORTANT : Pour utiliser ces fonctions, le composant qui les importe doit définir les propriétés auxquelles chaque fonction accède
 * - via data ou props : item, items
 */

/**
 * Getter à utiliser pour accéder à l'URL absolue d'une image de couverture
 */
const couvertureSrc = function () {
  return StrapiService.getImageUrl(this.item.attributes.couverture);
}

/**
 * Getter à utiliser pour accéder au texte alternatif d'une image de couverture
 */
const couvertureAlt = function () {
  return this.item.attributes.couverture?.data?.attributes.alternativeText || "";
}

/**
 * Getter à utiliser pour récupérer la date de publication d'un objet
 */
const datePublication = function () {
  return new Date(this.item.attributes.publishedAt).toLocaleDateString();
}

/**
 * Date et heure des évènements, dans un format human-friendly
 */
const dateHeure = function () {
  let dateHeure = this.item.attributes.dateFin
    ? `Du ${UtilsService.isoSqlDateToFrenchDate(this.item.attributes.dateDebut)} 
      au ${UtilsService.isoSqlDateToFrenchDate(this.item.attributes.dateFin)}`
    : `Le ${UtilsService.isoSqlDateToFrenchDate(this.item.attributes.dateDebut)}`;
  if (this.item.attributes.heureDebut) {
    dateHeure += this.item.attributes.heureFin
      ? ` de ${this.item.attributes.heureDebut.slice(0, 5)} 
        à ${this.item.attributes.heureFin.slice(0, 5)}`
      : ` à ${this.item.attributes.heureDebut.slice(0, 5)}`;
  }
  return dateHeure;
}

const actualite = {
  computed: {
    couvertureSrc,
    couvertureAlt,
    datePublication,
  },
};

const evenement = {
  computed: {
    couvertureSrc,
    couvertureAlt,
    dateHeure,
  },
};

export default StrapiService;
export { actualite, evenement };
