import { EtapeCommande } from "../services/appro.service";
import UtilsService from "../services/utils.service";

/**
 * Identification des tableaux de l'application
 * (pour communication avec le store)
 */
const TableKeys = {
  // Module DECLARATIONS
  PARCELLES: "parcelles",
  EMBLAVEMENTS: "emblavements",
  AUTOCONSOMMATION: "autoconsommation",
  ENLEVEMENTS: "enlevements",
  MOISSON: "moisson",
  // Module APPRO
  COMMANDES_APPRO_EXTRANET: "commandes-appro-extranet",
  BONS_COMMANDE_APPRO: "bons-commande-appro",
  BONS_LIVRAISON_APPRO: "bons-livraison-appro",
  // Module COLLECTE
  ANALYSES_MOISSON: "analyses-moisson",
  BONS_APPORT: "bons-apport",
  CONTRATS_APPORT: "contrats-apport",
  // Module ADMINISTRATIF
  CERTIFICATS: "certificats",
  RELEVE_COMPTE: "releve-compte",
  REPONSES_AG: "reponses-ag",
  // Module COMMUNICATION
  COM_TEMPLATES: "com-templates",
  COM_HISTORIQUES: "com-historiques",
  // Module BACKOFFICE
  UTILISATEURS: "utilisateurs",
  MAILS: "mails",
  //STATISTIQUES: "statistiques",
  BATCHS: "batchs",
  //PARAMETRES: "parametres",
  // Module REFERENTIEL
  TIERS: "tiers",
  ESPECES: "especes",
  VARIETES: "varietes",
  PRODUITS_CEREALE: "produits-cereale",
  PRODUITS_APPRO: "produits-appro",
};

/**
 * Valeurs par défaut des pageables de chaque écran
 * /!\ La property sortBy correspond à la property key (et non sortBy) du field correspondant
 */
const defaultPageables = new Map();
// Module DECLARATIONS
defaultPageables.set(TableKeys.PARCELLES, { currentPage: 1, sortBy: ["ilot.libelle"], sortDesc: [false], });
defaultPageables.set(TableKeys.EMBLAVEMENTS, { currentPage: 1, sortBy: ["libelle"], sortDesc: [false], });
defaultPageables.set(TableKeys.AUTOCONSOMMATION, { currentPage: 1, sortBy: ["libelleProduit"], sortDesc: [false], });
defaultPageables.set(TableKeys.ENLEVEMENTS, { currentPage: 1, sortBy: ["libelleProduit"], sortDesc: [false], });
defaultPageables.set(TableKeys.MOISSON, { currentPage: 1, sortBy: ["statut"], sortDesc: [false], });
// Module APPRO
defaultPageables.set(TableKeys.COMMANDES_APPRO_EXTRANET, { currentPage: 1, sortBy: ["dateOperation"], sortDesc: [true], });
defaultPageables.set(TableKeys.BONS_COMMANDE_APPRO, { currentPage: 1, sortBy: ["dateOperation"], sortDesc: [true], });
defaultPageables.set(TableKeys.BONS_LIVRAISON_APPRO, { currentPage: 1, sortBy: ["dateLivraison"], sortDesc: [true], });
// Module COLLECTE
defaultPageables.set(TableKeys.ANALYSES_MOISSON, { currentPage: 1, sortBy: ["codeRecolte"], sortDesc: [true], });
defaultPageables.set(TableKeys.BONS_APPORT, { currentPage: 1, sortBy: ["dateApport"], sortDesc: [true], });
defaultPageables.set(TableKeys.CONTRATS_APPORT, { currentPage: 1, sortBy: ["codeRecolte"], sortDesc: [true], });
// Module ADMINISTRATIF
defaultPageables.set(TableKeys.CERTIFICATS, { currentPage: 1, sortBy: ["dateHeureDepot"], sortDesc: [true], });
defaultPageables.set(TableKeys.RELEVE_COMPTE, { currentPage: 1, sortBy: ["dateValeur"], sortDesc: [true], });
defaultPageables.set(TableKeys.REPONSES_AG, { currentPage: 1, sortBy: ["codeTiers"], sortDesc: [false], });
// Module COMMUNICATION
defaultPageables.set(TableKeys.COM_TEMPLATES, { currentPage: 1, sortBy: ["modifieLe"], sortDesc: [true], });
defaultPageables.set(TableKeys.COM_HISTORIQUES, { currentPage: 1, sortBy: ["modifieLe"], sortDesc: [true], });
// Module BACKOFFICE
defaultPageables.set(TableKeys.UTILISATEURS, { currentPage: 1, sortBy: ["email"], sortDesc: [false], });
defaultPageables.set(TableKeys.MAILS, { currentPage: 1, sortBy: ["sentAt"], sortDesc: [true], });
//defaultPageables.set(TableKeys.STATISTIQUES, { currentPage: 1, sortBy: [""], sortDesc: [false], });
defaultPageables.set(TableKeys.BATCHS, { currentPage: 1, sortBy: ["startTime"], sortDesc: [true], });
//defaultPageables.set(TableKeys.PARAMETRES, { currentPage: 1, sortBy: [""], sortDesc: [false], });
// Module REFERENTIEL
defaultPageables.set(TableKeys.TIERS, { currentPage: 1, sortBy: ["codeTiers"], sortDesc: [false], });
defaultPageables.set(TableKeys.ESPECES, { currentPage: 1, sortBy: ["codeEspece"], sortDesc: [false], });
defaultPageables.set(TableKeys.VARIETES, { currentPage: 1, sortBy: ["codeVariete"], sortDesc: [false], });
defaultPageables.set(TableKeys.PRODUITS_CEREALE, { currentPage: 1, sortBy: ["codeProduit"], sortDesc: [false], });
defaultPageables.set(TableKeys.PRODUITS_APPRO, { currentPage: 1, sortBy: ["codeProduit"], sortDesc: [false], });

// OPERATEURS DE COALESCENCE
const isNull = { code: "$null", label: "est nul", minOperands: 0, maxOperands: 0, };
const isNotNull = { code: "$notNull", label: "est non nul", minOperands: 0, maxOperands: 0, };
// OPERATEURS D'EGALITE
const isEqual = { code: "$eq", label: "=", minOperands: 1, maxOperands: 1, };
const isNotEqual = { code: "$ne", label: "≠", minOperands: 1, maxOperands: 1, };
// OPERATEURS DE COMPARAISON
const isLt = { code: "$lt", label: "<", minOperands: 1, maxOperands: 1, };
const isLte = { code: "$lte", label: "≤", minOperands: 1, maxOperands: 1, };
const isGt = { code: "$gt", label: ">", minOperands: 1, maxOperands: 1, };
const isGte = { code: "$gte", label: "≥", minOperands: 1, maxOperands: 1, };
const isBtw = { code: "$between", label: "dans la plage", minOperands: 2, maxOperands: 2, };
const isNotBtw = { code: "$notBetween", label: "hors de la plage", minOperands: 2, maxOperands: 2, };
// OPERATEURS D'ENSEMBLE
const isIn = { code: "$in", label: "dans la liste", minOperands: 1, maxOperands: 1000, };
const isNotIn = { code: "$notIn", label: "hors de la liste", minOperands: 1, maxOperands: 1000, };
// OPERATEURS D'EXPRESSION REGULIERE
//const isLike = { code: "$like", label: "like", minOperands: 1, maxOperands: 1, enumerable: false, };
//const isNotLike = { code: "$notLike", label: "not like", minOperands: 1, maxOperands: 1, enumerable: false, };
const startsWith = { code: "$startsWith", label: "commence par", minOperands: 1, maxOperands: 1, enumerable: false, };
const notStartsWith = { code: "$notStartsWith", label: "ne commence pas par", minOperands: 1, maxOperands: 1, enumerable: false, };
const contains = { code: "$contains", label: "contient", minOperands: 1, maxOperands: 1, enumerable: false, };
const notContains = { code: "$notContains", label: "ne contient pas", minOperands: 1, maxOperands: 1, enumerable: false, };
const endsWith = { code: "$endsWith", label: "finit par", minOperands: 1, maxOperands: 1, enumerable: false, };
const notEndsWith = { code: "$notEndsWith", label: "ne finit pas par", minOperands: 1, maxOperands: 1, enumerable: false, };

/**
 * Map des opérateurs de filtrage pris en charge indexés par code opération
 */
const operations = [
  isNull, isNotNull, isEqual, isNotEqual, isLt, isLte, isGt, isGte, isBtw, isNotBtw, isIn, isNotIn,
  startsWith, notStartsWith, contains, notContains, endsWith, notEndsWith,
];
const operationsByCode = new Map(operations.map(o => [o.code, o]));

/**
 * Map des opérateurs de filtrage pris en charge indexés par type de donnée
 */
const operationsByType = new Map();
operationsByType.set("text", [
  isNull, isNotNull, isEqual, isNotEqual, isLt, isLte, isGt, isGte, isBtw, isNotBtw, isIn, isNotIn,
  startsWith, notStartsWith, contains, notContains, endsWith, notEndsWith,
]);
operationsByType.set("date", [isNull, isNotNull, isEqual, isNotEqual, isLt, isLte, isGt, isGte, isBtw, isNotBtw, isIn, isNotIn,]);
operationsByType.set("datetime", [isNull, isNotNull, isLt, isLte, isGt, isGte, isBtw, isNotBtw,]);
operationsByType.set("boolean", [isNull, isNotNull, isEqual, isNotEqual,]);
operationsByType.set("number", [isNull, isNotNull, isEqual, isNotEqual, isLt, isLte, isGt, isGte, isBtw, isNotBtw, isIn, isNotIn,]);

/**
 * Propriétés partagées des filtres
 * (i.e. valeur unique partagée dans toute l'appli)
 */
let sharedFilters = new Map();
sharedFilters.set("codeRecolte", null);

/**
 * Paramétrage des filtres de chaque écran
 * Format { property: String, label: String, type: String } pour tous les filtres
 * Ajouter { ..., enumerator?: (store) => Array } pour énumérer les valeurs possibles (null par défaut)
 *   /!\ Permet de choisir les opérandes dans un select plutôt que d'avoir à les saisir manuellement
 * Ajouter { ..., sharedKey?: String } pour identifier les filtres partagés (null par défaut)
 *   /!\ Un filtre partagé (générique ou custom) doit être utilisé de la même façon dans tous les écrans /!\
 * Ajouter { ..., custom?: Boolean } pour indiquer qu'il s'agit d'un filtre sur mesure (false par défaut)
 *   /!\ Nécessite la propriété operation pour masquer le champ operation sur l'interface /!\
 * Ajouter { ..., operation?: String,  } pour verrouiller/masquer l'opération (null/visible par défaut)
 *   /!\ Penser à donner une indication de l'opération utilisée dans le libellé du filtre /!\
 * Ajouter { ..., ignoreCase?: Boolean,  } pour verrouiller/masquer l'option (false/visible par défaut)
 *   /!\ Réservé aux filtres de type "text", bien entendu /!\
 * Ajouter { ..., ignoreAccents?: Boolean,  } pour verrouiller/masquer l'option (false/visible par défaut)
 *   /!\ Réservé aux filtres de type "text", bien entendu /!\
 * Ajouter { ..., operations?: Array<String> } pour limiter les opérations autorisées (toutes par défaut)
 *   /!\ Ignoré si operation est renseigné /!\
 * 
 * # USAGE AVEC LE STORE
 * 
 * Les filtres génériques sont compilés dans une fonction de filtrage unique utilisable avec Array.filter
 * Ajouter { ..., filterFunction: (value, filter, metadata) => Boolean } pour implémenter un filtre sur mesure
 *   /!\ Nécessite "custom: true" ; valable uniquement pour l'usage avec le store /!\
 * 
 * Toutes les propriétés utilisées doivent être présentes sur les objets filtrés pour que le filtrage fonctionne
 *   /!\ Cela implique de charger/itérer tout le tableau avant de le filtrer, ne convient donc pas pour de gros volumes de données
 *   // TODO Voir s'il est possible d'explorer un graphe relationnel façon SQL avec IndexedDB ?
 * 
 * # USAGE AVEC L'API
 * 
 * Les filtres génériques seront soumis dans une conjonction AND d'un seul niveau
 * Les filtres sur mesure seront soumis au format property: value (sans opération)
 * La façon dont les filtres sur mesure sont utilisés dépend de leur implémentation spécifique
 * 
 * Exemple de payload :
 * {
 *   // Filtres génériques
 *   filters: { operation: "$and", operands: [
 *     { property: p1, operation: "$eq", operands: ["toto"], },
 *     { property: p2, operation: "$notNull", operands: [], },
 *   ]},
 *   // Filtres sur mesure
 *   specification: {
 *     customProperty1: customValue1,
 *     customProperty2: customValue2,
 *   },
 *   // Infos de pagination
 *   pageable: pageable,
 * }
 */
const filtersMetadata = new Map();

// FILTRES PARTAGES
const codeRecolteFilterDefinition = {
  property: "recolte.codeRecolte", label: "Campagne", type: "number", sharedKey: "codeRecolte", operation: "$eq",
  enumerator: (store) => {
    let recolteEnCoursDeSaisie = store.getters["ref/recolteEnCoursDeSaisie"];
    return [{ value: null, text: "" }, ...Array.from([0, 1, 2, 3, 4], (x) => recolteEnCoursDeSaisie - x)];
  },
};

const dateOperations = ["$eq", "$ne", "$lt", "$lte", "$gt", "$gte", "$between", "$notBetween"];
const numberOperations = dateOperations;

// Module DECLARATIONS
filtersMetadata.set(TableKeys.PARCELLES, [
  { property: "ilot.libelle", label: "Ilot", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelle", label: "Parcelle", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "surfaceEmblavee", label: "Surface totale (ha)", type: "number", operations: numberOperations, },
  { property: "surfaceRetiree", label: "Surface abandonnée (ha)", type: "number", operations: numberOperations, },
]);
filtersMetadata.set(TableKeys.EMBLAVEMENTS, [
  { property: "libelleParcelle", label: "Parcelle", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "etatParcelle", label: "Etat parcelle", type: "text", custom: true, operation: "$eq",
    enumerator: () => [
      { value: null, text: "", },
      { value: "NON_SAISIE", text: "A saisir" },
      { value: "INTENTION", text: "Saisie" },
      { value: "SEMEE", text: "Semée" },
      { value: "MOISSONNEE", text: "Récoltée" },
    ],
    filterFunction: (value, filter) => {
      if (filter.operands[0] === "NON_SAISIE") {
        return value.emblavements.length <= 0;
      }
      return value.etatParcelle === filter.operands[0] && value.emblavements.length > 0;
    },
  },
  {
    property: "emblavements.length", label: "Mélange d'espèces", type: "number", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: 1, text: "Non" }, { value: 2, text: "Oui" },],
  },
  {
    property: "emblavements.produit.libelle", label: "Culture", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true,
    filterFunction: (value, filter) => {
      let filterValue = UtilsService.replaceAccents(filter.operands[0]).toLowerCase();
      return !!value.emblavements
        .map(e => UtilsService.replaceAccents(e.produit.libelle).toLowerCase())
        .find(p => p.includes(filterValue));
    },
  },
  {
    property: "emblavements.variete.libelle", label: "Variété", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true,
    filterFunction: (value, filter) => {
      let filterValue = UtilsService.replaceAccents(filter.operands[0]).toLowerCase();
      return !!value.emblavements
        .map(e => UtilsService.replaceAccents(e.variete.libelle).toLowerCase())
        .find(v => v.includes(filterValue));
    },
  },
]);
filtersMetadata.set(TableKeys.AUTOCONSOMMATION, [
  { property: "libelleProduit", label: "Culture", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelleVariete", label: "Variété", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "isMelangeEspeces", label: "Mélange d'espèces", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: false, text: "Non" }, { value: true, text: "Oui" },],
  },
  {
    property: "isMelangeVarietes", label: "Mélange de variétés", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: false, text: "Non" }, { value: true, text: "Oui" },],
  },
  {
    property: "autoconso.motif.code", label: "Motif autoconsommation", type: "text", custom: true, operation: "$eq",
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.ref.motifsAutoconso.map(m => ({ value: m.code, text: m.libelle, })))]
    },
    filterFunction: (value, filter) => {
      return value.autoconso.codeMotif === filter.operands[0] || value.emblavements.find(e => e.autoconso.codeMotif === filter.operands[0])
    },
  },
  { property: "quantiteAutoconsommee", label: "Quantité autoconsommée (t)", type: "number", operations: numberOperations, },
]);
filtersMetadata.set(TableKeys.ENLEVEMENTS, [
  { property: "libelleProduit", label: "Culture", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelleVariete", label: "Variété", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "isMelangeEspeces", label: "Mélange d'espèces", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: false, text: "Non" }, { value: true, text: "Oui" },],
  },
  {
    property: "isMelangeVarietes", label: "Mélange de variétés", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: false, text: "Non" }, { value: true, text: "Oui" },],
  },
  {
    property: "enlevement.mode.code", label: "Mode enlèvement", type: "text", custom: true, operation: "$eq",
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.ref.modesEnlevement.map(m => ({ value: m.code, text: m.libelle, })))]
    },
    filterFunction: (value, filter) => {
      return value.enlevement?.codeMode === filter.operands[0] || value.emblavements.find(e => e.enlevement?.codeMode === filter.operands[0])
    },
  },
  { property: "enlevement.dateLimite", label: "Date limite d'enlèvement", type: "date", operations: dateOperations, },
]);
filtersMetadata.set(TableKeys.MOISSON, [
  {
    property: "libelleProduit", label: "Culture", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true,
    filterFunction: (value, filter) => {
      let libelleProduit = UtilsService.replaceAccents(value.parcelles[0].culture.libelleProduit).toLowerCase();
      let filterValue = UtilsService.replaceAccents(filter.operands[0]).toLowerCase();
      return libelleProduit.includes(filterValue);
    },
  },
  {
    property: "libelleVariete", label: "Variété", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true,
    filterFunction: (value, filter) => {
      let filterValue = UtilsService.replaceAccents(filter.operands[0]).toLowerCase();
      return !!value.parcelles
        .map((p) => UtilsService.replaceAccents(p.culture.libelleVariete).toLowerCase())
        .find((v) => v.includes(filterValue));
    },
  },
  {
    property: "libelleParcelle", label: "Parcelle", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true,
    filterFunction: (value, filter) => {
      let filterValue = UtilsService.replaceAccents(filter.operands[0]).toLowerCase();
      return !!value.parcelles
        .map((p) => UtilsService.replaceAccents(p.libelleParcelle).toLowerCase())
        .find((v) => v.includes(filterValue));
    },
  },
  {
    property: "statut.libelle", label: "Statut", type: "text", operation: "$eq",
    enumerator: function (store) {
      let options = [{ value: null, text: "" }];
      for (const property in store.state.decl.statutsMoisson) {
        let statut = store.state.decl.statutsMoisson[property];
        options.push({ value: statut.libelle, text: statut.libelle });
      }
      return options;
    },
  },
  { property: "quantiteMoissonnee", label: "Quantité moissonnée (t)", type: "number", operations: numberOperations, },
]);

// Module APPRO
filtersMetadata.set(TableKeys.COMMANDES_APPRO_EXTRANET, [
  { property: "entete.modifieLe", label: "Date commande", type: "datetime", operations: dateOperations, },
  { property: "conditionnement.produitAppro.libelle", label: "Article", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "quantiteCommandee", label: "Quantité commandée", type: "number", operations: numberOperations, },
  {
    property: "entete.etape", label: "Statut commande", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: false,
    enumerator: () => [{ value: null, text: "", }, ...EtapeCommande.values.map(v => ({ value: v.name, text: v.libelle }))],
  },
]);
filtersMetadata.set(TableKeys.BONS_COMMANDE_APPRO, [
  { property: "entete.dateOperation", label: "Date commande", type: "date", operations: dateOperations, },
  { property: "entete.numeroBon", label: "N° commande", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelleProduit", label: "Article", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "quantiteCommandee", label: "Quantité commandée", type: "number", operations: numberOperations, },
]);
filtersMetadata.set(TableKeys.BONS_LIVRAISON_APPRO, [
  { property: "entete.dateLivraison", label: "Date livraison", type: "date", operations: dateOperations, },
  { property: "entete.numeroBon", label: "N° livraison", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelleProduit", label: "Article", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "quantiteLivree", label: "Quantité livrée", type: "number", operations: numberOperations, },
]);

// Module COLLECTE
filtersMetadata.set(TableKeys.ANALYSES_MOISSON, [
  codeRecolteFilterDefinition,
  { property: "produit.libelle", label: "Culture", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "datePrelevement", label: "Date échantillon", type: "date", operations: dateOperations, },
  { property: "dateAnalyse", label: "Date analyse", type: "date", operations: dateOperations, },
  { property: "quantiteContrat", label: "Poids du lot (t)", type: "number", operations: numberOperations, },
]);
filtersMetadata.set(TableKeys.BONS_APPORT, [
  codeRecolteFilterDefinition,
  { property: "dateApport", label: "Date apport", type: "date", operations: dateOperations, },
  { property: "numeroApport", label: "N° apport", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "produit.libelle", label: "Culture", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "poidsLivre", label: "Poids livré (t)", type: "number", operations: numberOperations, },
  { property: "poidsAuxNormes", label: "Poids aux normes (t)", type: "number", operations: numberOperations, },
  {
    property: "fichierIndexe.idFichier", label: "Bon d'apport", type: "number",
    operations: [{ code: "$null", label: "Indisponible" }, { code: "$notNull", label: "Disponible" },],
  },
]);
filtersMetadata.set(TableKeys.CONTRATS_APPORT, [
  codeRecolteFilterDefinition,
  { property: "produit.libelle", label: "Culture", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "certificatBioOk", label: "Certificat Bio", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Valide", }, { value: false, text: "Invalide" },],
  },
  { property: "quantiteContrat", label: "Poids attendu (t)", type: "number", operations: numberOperations, },
  { property: "quantiteLivree", label: "Poids déjà livré (t)", type: "number", operations: numberOperations, },
  { property: "quantiteSolde", label: "Reste à livrer (t)", type: "number", operations: numberOperations, },
  {
    property: "libelleStatut", label: "Statut", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: false,
    enumerator: () => [{ value: null, text: "", }, "En cours", "En attente", "Soldé", "Annulé"],
  },
]);

// Module ADMINISTRATIF
filtersMetadata.set(TableKeys.CERTIFICATS, []);
filtersMetadata.set(TableKeys.RELEVE_COMPTE, [
  { property: "dateValeur", label: "Date de valeur", type: "date", operations: dateOperations, },
  { property: "dateEcheance", label: "Date d'échéance", type: "date", operations: dateOperations, },
  { property: "libelleTypeMouvement", label: "Type mouvement", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "libelleMouvement", label: "Libellé mouvement", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "montantTtc", label: "Montant TTC (€)", type: "number", operations: numberOperations, },
  { property: "solde", label: "Solde (€)", type: "number", operations: numberOperations, },
  {
    property: "fichierIndexe.idFichier", label: "Document", type: "number",
    operations: [{ code: "$null", label: "Indisponible" }, { code: "$notNull", label: "Disponible" },],
  },
]);
filtersMetadata.set(TableKeys.REPONSES_AG, [
  { property: "tiers.codeTiers", label: "Code tiers est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true, },
  { property: "tiers.raisonSociale", label: "Raison sociale contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "typeDocument", label: "Type de document est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: () => [{ value: null, text: "", }, { value: "COUPON_REPONSE", text: "Coupon-réponse", }, { value: "BON_POUVOIR", text: "Bon pour pouvoir" },],
  },
  { property: "fichierIndexe.dateHeureIndexation", label: "Déposé le", type: "datetime", operations: dateOperations, },
]);

// Module COMMUNICATION
filtersMetadata.set(TableKeys.COM_TEMPLATES, [
  { property: "objet", label: "Objet du mail contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "template.libelle", label: "Libellé template contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "creeLe", label: "Créé le", type: "datetime", operations: dateOperations, },
  { property: "modifieLe", label: "Modifié le", type: "datetime", operations: dateOperations, },
]);
filtersMetadata.set(TableKeys.COM_HISTORIQUES, [
  { property: "objet", label: "Objet du mail contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "template.libelle", label: "Libellé template contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "modifieLe", label: "Envoyé le", type: "datetime", operations: dateOperations, },
]);

// Module BACKOFFICE 
filtersMetadata.set(TableKeys.UTILISATEURS, [
  {
    property: "codeSociete", label: "Code société est", type: "text", custom: true, operation: "$eq",
    enumerator: () => [{ value: null, text: "Toutes", }, { value: "BIOCER", text: "Biocer" }, { value: "COCEBI", text: "Cocebi" }, { value: "PROBIOLOR", text: "Probiolor" },],
  },
  { property: "email", label: "Email contient", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "nom", label: "Nom contient", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "prenom", label: "Prénom contient", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "codeTiers", label: "Code tiers est", type: "text", custom: true, operation: "$eq", ignoreCase: true, ignoreAccents: true, },
  { property: "raisonSociale", label: "Raison sociale contient", type: "text", custom: true, operation: "$contains", ignoreCase: true, ignoreAccents: true, },
]);
filtersMetadata.set(TableKeys.MAILS, [
  { property: "sentBy", label: "Emetteur", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "toAddress", label: "Destinataire", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "subject", label: "Objet", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "sentAt", label: "Envoyé le", type: "datetime", operations: dateOperations, },
  {
    property: "errorName", label: "Statut", type: "text",
    operations: [{ code: "$null", label: "Succès" }, { code: "$notNull", label: "Erreur" },],
  },
]);
filtersMetadata.set(TableKeys.BATCHS, [
  {
    property: "nomBatch", label: "Batch", type: "text", custom: true, operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: async function (store) {
      return await store.dispatch("ref/getBatchs", { all: true });
    },
  },
  { property: "dateDebut", label: "Entre le", type: "datetime", custom: true, operation: "$gte", },
  { property: "dateFin", label: "Et le", type: "datetime", custom: true, operation: "$lte", },
  {
    property: "statut", label: "Statut", type: "text", custom: true, operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: async function (store) {
      return await store.dispatch("ref/getBatchsStatus", { value: null, all: true });
    },
  },
]);

// Module REFERENTIEL
filtersMetadata.set(TableKeys.TIERS, [
  {
    property: "societePrincipale.codeTiers", label: "Code société est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.cooperatives.map(s => s.code))]
    },
  },
  { property: "codeTiers", label: "Code tiers est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true, },
  { property: "raisonSociale", label: "Raison sociale contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  { property: "typeTiers.codeTypeTiers", label: "Type tiers est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true, },
  {
    property: "actif", label: "Statut", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Actif", }, { value: false, text: "Inactif" },],
  },
]);
filtersMetadata.set(TableKeys.ESPECES, [
  { property: "libelle", label: "Libellé espèce contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "active", label: "Statut", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Actif", }, { value: false, text: "Inactif" },],
  },
]);
filtersMetadata.set(TableKeys.VARIETES, [
  {
    property: "espece.codeEspece", label: "Espèce est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.ref.especes.map(e => ({ value: e.codeEspece, text: e.libelle, })))]
    },
  },
  { property: "libelle", label: "Libellé variété contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "active", label: "Statut", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Actif", }, { value: false, text: "Inactif" },],
  },
]);
filtersMetadata.set(TableKeys.PRODUITS_CEREALE, [
  {
    property: "espece.codeEspece", label: "Espèce est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.ref.especes.map(e => ({ value: e.codeEspece, text: e.libelle, })))]
    },
  },
  {
    property: "debouche.code", label: "Débouché est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: (store) => [{ value: null, text: "" }, ...(store.state.ref.debouches.map(d => ({ value: d.code, text: d.libelle, })))],
  },
  { property: "libelle", label: "Libellé produit contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "actif", label: "Statut", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Actif", }, { value: false, text: "Inactif" },],
  },
]);
filtersMetadata.set(TableKeys.PRODUITS_APPRO, [
  {
    property: "espece.codeEspece", label: "Espèce est", type: "text", operation: "$eq", ignoreCase: true, ignoreAccents: true,
    enumerator: function (store) {
      return [{ value: null, text: "" }, ...(store.state.ref.especes.map(e => ({ value: e.codeEspece, text: e.libelle, })))]
    },
  },
  { property: "libelle", label: "Libellé produit contient", type: "text", operation: "$contains", ignoreCase: true, ignoreAccents: true, },
  {
    property: "actif", label: "Statut", type: "boolean", operation: "$eq",
    enumerator: () => [{ value: null, text: "", }, { value: true, text: "Actif", }, { value: false, text: "Inactif" },],
  },
]);

/**
 * Dictionnaire contenant la définition des champs pour chaque table
 * 
 * La property key correspond à l'ID du field
 * La property sortBy désigne la propriété de l'objet à utiliser pour le tri alphanumérique
 * La property sortFunction est une factory permettant d'implémenter un tri plus complexe
 */
const fields = new Map();

// Module DECLARATIONS
fields.set(TableKeys.PARCELLES, [
  { key: "ilot.libelle", label: "Ilot", sortable: true, },
  { key: "libelle", label: "Parcelle", sortable: true, },
  {
    key: "surfaceEmblavee", label: "Surface totale (ha) dont abandonnée", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("surfaceEmblavee", sortDirection),
    thStyle: { width: "200px" }, alignH: "right",
  },
  {
    key: "surfaceRetiree", label: "Surface abandonnée (ha)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("surfaceRetiree", sortDirection),
    thStyle: { width: "200px" }, alignH: "right", breakpoint: "md",
  },
  { key: "actions", label: "Actions", thStyle: { width: "160px" }, class: "d-print-none", },
]);
fields.set(TableKeys.EMBLAVEMENTS, [
  { key: "libelle", label: "Parcelle", sortable: true, sortBy: "libelleParcelle", },
  {
    key: "produit", label: "Culture", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "emblavements",
      UtilsService.sortByStringProperty("produit.libelle", sortDirection),
      true
    ),
  },
  {
    key: "variete", label: "Variété", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "emblavements",
      UtilsService.sortByStringProperty("variete.libelle", sortDirection),
      true
    ),
  },
  {
    key: "surface", label: "Surface cultivée (ha)", sortable: true,
    sortFunction: (sortDirection) => (a, b) =>
      (a.surfaceEmblavee - a.surfaceRetiree - (b.surfaceEmblavee - b.surfaceRetiree)) * sortDirection,
    thStyle: { width: "100px" }, alignH: "right", breakpoint: "md",
  },
  {
    key: "rendement", label: "Rendement prévisionnel (t/ha)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "emblavements",
      UtilsService.sortByNumberProperty("rendementAgriculteur", sortDirection),
      true
    ),
    thStyle: { width: "100px" }, alignH: "right", breakpoint: "md",
  },
  {
    key: "quantitePrevisionnelle", label: "Quantité prévisionnelle (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "emblavements",
      UtilsService.sortByNumberProperty("quantitePrevisionnelle", sortDirection),
      true
    ),
    thStyle: { width: "100px" }, alignH: "right", breakpoint: "md",
  },
  { key: "etatParcelle", label: "Etat", sortable: true, thStyle: { width: "140px" }, },
  { key: "actions", label: "Actions", thStyle: { width: "160px" }, class: "d-print-none", },
]);
fields.set(TableKeys.MOISSON, [
  {
    key: "parcelle", label: "Parcelle(s)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "parcelles",
      UtilsService.sortByStringProperty("libelleParcelle", sortDirection),
      true
    ),
  },
  {
    key: "libelleProduit", label: "Culture", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "parcelles",
      UtilsService.sortByStringProperty("culture.libelleProduit", sortDirection),
      true
    ),
  },
  {
    key: "libelleVariete", label: "Variété", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "parcelles",
      UtilsService.sortByStringProperty("culture.libelleVariete", sortDirection),
      true
    ),
  },
  {
    key: "quantitePrevisionnelle", label: "Quantité prévisionnelle (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByArrayProperty(
      "parcelles",
      UtilsService.sortByNumberProperty("quantitePrevisionnelle", sortDirection),
      true
    ),
    thStyle: { width: "160px" }, alignH: "right", breakpoint: "md",
  },
  {
    key: "statut", label: "Statut", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("statut.order", sortDirection),
  },
  {
    key: "quantiteMoissonnee", label: "Quantité réelle moissonnée (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("quantiteMoissonnee", sortDirection, -1),
    thStyle: { width: "160px" }, alignH: "right", breakpoint: "md",
  },
  { key: "actions", label: "Actions", thStyle: { width: "160px" }, class: "d-print-none", },
]);
fields.set(TableKeys.AUTOCONSOMMATION, [
  { key: "expand", label: "", },
  { key: "libelleProduit", label: "Culture", sortable: true, },
  { key: "libelleVariete", label: "Variété", sortable: true, },
  {
    key: "quantiteProduite", label: "Quantité produite (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("quantiteProduite", sortDirection),
    thStyle: { width: "140px" }, alignH: "right", breakpoint: "md",
  },
  { key: "motifAutoconso", label: "Motif d'autoconso", sortable: true, sortBy: "autoconso.motif.libelle", breakpoint: "md", },
  {
    key: "quantiteAutoconsommee", label: "Quantité autoconso (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("autoconso.quantiteAutoconsommee", sortDirection),
    thStyle: { width: "140px" }, alignH: "right",
  },
  { key: "actions", label: "Actions", thStyle: { width: "120px" }, class: "d-print-none", },
]);
fields.set(TableKeys.ENLEVEMENTS, [
  { key: "expand", label: "", },
  { key: "libelleProduit", label: "Culture", sortable: true, },
  { key: "libelleVariete", label: "Variété", sortable: true, },
  {
    key: "quantiteACollecter", label: "Quantité à collecter (t)", sortable: true,
    sortFunction: (sortDirection) => UtilsService.sortByNumberProperty("quantiteACollecter", sortDirection),
    thStyle: { width: "120px" }, alignH: "right", breakpoint: "md",
  },
  { key: "modeEnlevement", label: "Mode d'enlèvement", sortable: true, sortBy: "enlevement.mode.libelle", },
  { key: "dateEnlevement", label: "Date d'enlèvement au plus tard", sortable: true, sortBy: "enlevement.dateLimite", thStyle: { width: "180px" }, },
  { key: "actions", label: "Actions", thStyle: { width: "160px" }, class: "d-print-none", },
]);

// Module APPRO
fields.set(TableKeys.COMMANDES_APPRO_EXTRANET, [
  { key: "dateOperation", label: "Date commande", sortable: true, sortBy: "entete.modifieLe", },
  { key: "numeroBon", label: "N° bon", sortable: true, sortBy: "entete.idEntete", },
  { key: "numeroLigne", label: "N° ligne", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "libelleProduit", label: "Article", sortable: true, sortBy: "conditionnement.produitAppro.libelle", },
  { key: "quantiteCommandee", label: "Quantité commandée", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "unite", label: "Unité", sortable: true, sortBy: "conditionnement.produitAppro.uniteVente", thStyle: { width: "100px" }, },
  { key: "statut", label: "Statut", sortable: true, sortBy: "entete.etape", thStyle: { width: "100px" }, },
]);
fields.set(TableKeys.BONS_COMMANDE_APPRO, [
  { key: "dateOperation", label: "Date commande", sortable: true, sortBy: "entete.dateOperation", },
  { key: "numeroBon", label: "N° bon", sortable: true, sortBy: "entete.numeroBon", },
  { key: "numeroLigne", label: "N° ligne", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "libelleProduit", label: "Article", sortable: true, },
  { key: "quantiteCommandee", label: "Quantité commandée", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "unite", label: "Unité", sortable: true, thStyle: { width: "100px" }, },
  { key: "prixNet", label: "Prix net (€/unité)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "montantHt", label: "Montant HT (€)", sortable: false, thStyle: { width: "100px" }, alignH: "right", },
  { key: "fichier", label: "Bon de commande", alignH: "center", class: "d-print-none", },
]);
fields.set(TableKeys.BONS_LIVRAISON_APPRO, [
  { key: "dateLivraison", label: "Date livraison", sortable: true, sortBy: "entete.dateLivraison", },
  { key: "numeroBon", label: "N° bon", sortable: true, sortBy: "entete.numeroBon", },
  { key: "numeroLigne", label: "N° ligne", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "libelleProduit", label: "Article", sortable: true, },
  { key: "quantiteLivree", label: "Quantité livrée", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "unite", label: "Unité", sortable: true, thStyle: { width: "100px" }, },
  { key: "prixNet", label: "Prix net (€/unité)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "montantHt", label: "Montant HT (€)", sortable: false, thStyle: { width: "100px" }, alignH: "right", },
  { key: "fichier", label: "Bon de livraison", alignH: "center", class: "d-print-none", },
]);

// Module COLLECTE
fields.set(TableKeys.ANALYSES_MOISSON, [
  { key: "codeRecolte", label: "Campagne", sortable: true, sortBy: "recolte.codeRecolte", thStyle: { width: "120px" }, alignH: "right", },
  { key: "culture", label: "Culture", sortable: true, sortBy: "produit.libelle", },
  { key: "datePrelevement", label: "Echantillon", sortable: true, },
  { key: "dateAnalyse", label: "Analyse", sortable: true, },
  { key: "lieuStockageTas", label: "Stockage", sortable: true, },
  { key: "quantiteContrat", label: "Poids du lot (t)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "tauxHumidite", label: "Taux d'humidité (%)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "tauxImpurete", label: "Taux d'impureté (%)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "poidsSpecifique", label: "Poids spécifique (kg/hL)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "fichier", label: "Résultat d'analyse", alignH: "center", class: "d-print-none", },
]);
fields.set(TableKeys.BONS_APPORT, [
  { key: "codeRecolte", label: "Campagne", sortable: true, sortBy: "recolte.codeRecolte", thStyle: { width: "120px" }, alignH: "right", },
  { key: "dateApport", label: "Date d'apport", sortable: true, },
  { key: "numeroApport", label: "Apport", sortable: true, },
  { key: "culture", label: "Culture", sortable: true, sortBy: "produit.libelle", },
  { key: "poidsLivre", label: "Poids livré (t)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "poidsAuxNormes", label: "Poids aux normes (t)", sortable: true, thStyle: { width: "120px" }, alignH: "right", },
  { key: "tauxHumidite", label: "Taux d'humidité (%)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "tauxImpurete", label: "Taux d'impureté (%)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "poidsSpecifique", label: "Poids spécifique (kg/hL)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "fichier", label: "Bon d'apport", alignH: "center", class: "d-print-none", },
]);
fields.set(TableKeys.CONTRATS_APPORT, [
  { key: "codeRecolte", label: "Campagne", sortable: true, sortBy: "recolte.codeRecolte", thStyle: { width: "120px" }, alignH: "right", },
  { key: "culture", label: "Culture", sortable: true, sortBy: "produit.libelle", },
  { key: "certificatBioOk", label: "Certificat Bio", sortable: true, thStyle: { width: "140px" }, alignH: "center", },
  { key: "quantiteContrat", label: "Attendu (t)", sortable: true, thStyle: { width: "120px" }, alignH: "right", },
  { key: "quantiteLivree", label: "Livré (t)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "quantiteSolde", label: "Solde (t)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "libelleStatut", label: "Statut", sortable: true, thStyle: { width: "120px" }, },
]);

// Module ADMINISTRATIF
fields.set(TableKeys.CERTIFICATS, [
  { key: "dateHeureDepot", label: "Dépôt", sortable: true, },
  { key: "commentaire", label: "Commentaire", sortable: true, },
  { key: "fichier", label: "Certificat", alignH: "center", class: "d-print-none", },
]);
fields.set(TableKeys.RELEVE_COMPTE, [
  { key: "dateValeur", label: "Date de valeur", sortable: true, },
  { key: "dateEcheance", label: "Date d'échéance", sortable: true, },
  { key: "libelleTypeMouvement", label: "Type", sortable: true, },
  { key: "libelleMouvement", label: "Libellé", sortable: true, },
  { key: "montantTtc", label: "Montant TTC (€)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "solde", label: "Solde (€)", sortable: true, thStyle: { width: "100px" }, alignH: "right", },
  { key: "fichier", label: "Document", alignH: "center", class: "d-print-none", },
]);
fields.set(TableKeys.REPONSES_AG, [
  { key: "codeTiers", label: "Code tiers", sortable: true, sortBy: "tiers.codeTiers", },
  { key: "raisonSociale", label: "Raison sociale", sortable: true, sortBy: "tiers.raisonSociale", },
  { key: "dateHeureDepot", label: "Dépôt", sortable: true, sortBy: "fichierIndexe.dateHeureIndexation" },
  { key: "typeDocument", label: "Type de document", sortable: true, },
  { key: "commentaire", label: "Commentaire", sortable: true, },
  { key: "fichier", label: "Document", alignH: "center", class: "d-print-none", },
]);

// Module COMMUNICATION
fields.set(TableKeys.COM_TEMPLATES, [
  { key: "objet", label: "Objet du mail", sortable: true, },
  { key: "template.libelle", label: "Template", sortable: true, },
  { key: "creeLe", label: "Créé le", sortable: true, },
  { key: "creePar", label: "Créé par", sortable: true, },
  { key: "modifieLe", label: "Modifié le", sortable: true, },
  { key: "modifiePar", label: "Modifié par", sortable: true, },
]);
fields.set(TableKeys.COM_HISTORIQUES, [
  { key: "expand", label: "", },
  { key: "objet", label: "Objet du mail", sortable: true, },
  { key: "template.libelle", label: "Template", sortable: true, },
  { key: "modifieLe", label: "Envoyé le", sortable: true, },
  { key: "modifiePar", label: "Envoyé par", sortable: true, },
]);

// Module BACKOFFICE
fields.set(TableKeys.UTILISATEURS, [
  { key: "email", label: "Email", sortable: true, },
  { key: "prenom", label: "Prenom", sortable: true, sortBy: "contacts.prenom", },
  { key: "nom", label: "Nom", sortable: true, sortBy: "contacts.nom", },
  { key: "codeTiers", label: "Code tiers", sortable: true, sortBy: "contacts.tiers.codeTiers", },
  { key: "raisonSociale", label: "Raison sociale", sortable: true, sortBy: "contacts.tiers.raisonSociale", },
  { key: "actions", label: "Actions", class: "d-print-none", },
]);
fields.set(TableKeys.MAILS, [
  { key: "sentAt", label: "Envoyé le", sortable: true, },
  { key: "sentBy", label: "Emetteur", sortable: true, },
  { key: "sentTo", label: "Destinataire(s)", sortable: true, sortBy: "toAddress", },
  { key: "subject", label: "Objet", sortable: true, },
  { key: "errorName", label: "Statut", sortable: true, alignH: "center", },
  { key: "actions", label: "Actions", class: "d-print-none", },
]);
fields.set(TableKeys.BATCHS, [
  { key: "batchName", label: "Job", sortable: true, sortBy: "batch_name", },
  { key: "startTime", label: "Début", sortable: true, sortBy: "start_time", },
  { key: "endTime", label: "Fin", sortable: true, sortBy: "end_time", },
  { key: "exitCode", label: "Statut de fin", sortable: true, sortBy: "exit_code", },
  { key: "actions", label: "Actions", class: "d-print-none", },
]);

// Module REFERENTIEL
fields.set(TableKeys.TIERS, [
  { key: "codeTiers", label: "Code", sortable: true, },
  { key: "raisonSociale", label: "Raison sociale", sortable: true, },
  { key: "codeSociete", label: "Société", sortable: true, sortBy: "societePrincipale.codeTiers", },
  { key: "codeTypeTiers", label: "Type tiers", sortable: true, sortBy: "typeTiers.codeTypeTiers", },
  { key: "adresse", label: "Adresse", sortable: true, },
  { key: "telephonePortable", label: "Portable", sortable: true, thStyle: { width: "130px" }, },
  { key: "telephoneFixe", label: "Fixe", sortable: true, thStyle: { width: "130px" }, },
  { key: "telephoneFax", label: "Fax", sortable: true, thStyle: { width: "130px" }, },
  { key: "actif", label: "Statut", sortable: true, alignH: "center", },
]);
fields.set(TableKeys.ESPECES, [
  { key: "codeEspece", label: "Code", sortable: true, },
  { key: "libelle", label: "Libellé", sortable: true, },
  { key: "active", label: "Statut", sortable: true, alignH: "center", },
  { key: "creeLe", label: "Créé le", sortable: true, },
  { key: "modifieLe", label: "Modifié le", sortable: true, },
],)
fields.set(TableKeys.VARIETES, [
  { key: "codeVariete", label: "Code", sortable: true, },
  { key: "libelle", label: "Libellé", sortable: true, },
  { key: "active", label: "Statut", sortable: true, alignH: "center", },
  { key: "codeEspece", label: "Espèce", sortable: true, sortBy: "espece.codeEspece", },
  { key: "blacklist", label: "Masquée pour", },
  { key: "creeLe", label: "Créé le", sortable: true, },
  { key: "modifieLe", label: "Modifié le", sortable: true, },
]);
fields.set(TableKeys.PRODUITS_CEREALE, [
  { key: "codeProduit", label: "Code", sortable: true, },
  { key: "libelle", label: "Libellé", sortable: true, },
  { key: "actif", label: "Statut", sortable: true, alignH: "center", },
  { key: "estCollecte", label: "Collecté", sortable: true, alignH: "center", },
  { key: "codeEspece", label: "Espèce", sortable: true, sortBy: "espece.codeEspece", },
  { key: "codeVariete", label: "Variété", sortable: true, sortBy: "variete.codeVariete", },
  { key: "codeDebouche", label: "Débouché", sortable: true, sortBy: "debouche.code", },
  { key: "blacklist", label: "Masqué pour", },
  { key: "creeLe", label: "Créé le", sortable: true, },
  { key: "modifieLe", label: "Modifié le", sortable: true, },
]);
fields.set(TableKeys.PRODUITS_APPRO, [
  { key: "codeProduit", label: "Code", sortable: true, },
  { key: "libelle", label: "Libellé", sortable: true, },
  { key: "actif", label: "Statut", sortable: true, alignH: "center", },
  { key: "codeEspece", label: "Espèce", sortable: true, sortBy: "espece.codeEspece", },
  { key: "codeVariete", label: "Variété", sortable: true, sortBy: "variete.codeVariete", },
  { key: "blacklist", label: "Masqué pour", },
  { key: "creeLe", label: "Créé le", sortable: true, },
  { key: "modifieLe", label: "Modifié le", sortable: true, },
]);

export {
  TableKeys,
  defaultPageables,
  operationsByCode,
  operationsByType,
  sharedFilters,
  filtersMetadata,
  fields,
};
