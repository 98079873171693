import { apiPwfb } from "../api-pwfb";

class AccountService {
  searchAccounts(payload) {
    return apiPwfb.post("/accounts/search", payload);
  }
  getSelfAccount() {
    return apiPwfb.get("/account/");
  }
  getAccountStatus(email) {
    return apiPwfb.get(`/account/${email}/status`);
  }
  resetPassword(email) {
    return apiPwfb.post(`/account/${email}/password/reset`);
  }
  changePassword(presentPassword, newPassword) {
    return apiPwfb.post("/account/password/change", {
      presentPassword,
      newPassword,
    });
  }
  declareForgottenPassword(email) {
    return apiPwfb.get(`/account/${email}/password/forgotten`);
  }
  changeForgottenPassword(token, newPassword) {
    return apiPwfb.post("/account/password/forgotten", { token, newPassword });
  }
}

export default new AccountService();
