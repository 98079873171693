<template>
  <b-nav vertical>
    <b-nav-item :to="{ name: 'accueil' }">
      <AccueilMenuIcon></AccueilMenuIcon>&nbsp; Accueil
    </b-nav-item>
    <b-nav-item :to="{ name: 'exploitations' }">
      <ExploitationMenuIcon></ExploitationMenuIcon>&nbsp; Mes exploitations
    </b-nav-item>
    <!-- Menu coopérative -->
    <CollapsibleMenuComponent
      v-show="societe && pages.length > 0"
      icon="CooperativeMenuIcon"
      :title="societe.libelle"
      ref="menuCooperative"
      @show="closeMenusExcept('menuCooperative')"
    >
      <b-nav-item
        v-for="page in pages"
        :key="page.id"
        :to="{ name: 'page', params: { id: page.id } }"
      >
        {{ page.attributes.titre }}
      </b-nav-item>
    </CollapsibleMenuComponent>
    <!-- Menu campagnes -->
    <CollapsibleMenuComponent
      v-show="$store.getters['auth/hasAccesDeclarationsModule']"
      icon="DeclarationsMenuIcon"
      title="Déclarations"
      ref="menuDeclarations"
      @show="closeMenusExcept('menuDeclarations')"
    >
      <b-nav-item
        v-for="recolte in recoltes"
        :key="recolte"
        :to="{ name: 'recolte', params: { codeRecolte: recolte } }"
      >
        Campagne {{ recolte }}
      </b-nav-item>
    </CollapsibleMenuComponent>
    <!-- Menu appro -->
    <CollapsibleMenuComponent
      v-show="$store.getters['auth/hasAccesApproModule']"
      icon="ApproMenuIcon"
      title="Appro"
      ref="menuAppro"
      @show="closeMenusExcept('menuAppro')"
    >
      <b-nav-item 
        v-if="$store.getters['auth/hasAccesApproCatalogue']"
        :to="{ name: 'catalogues' }"
      >
        Catalogues
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters['auth/hasAccesApproCommande']"
        :to="{ name: 'commandes-appro-extranet' }"
      >
        Commandes extranet
      </b-nav-item>
      <b-nav-item :to="{ name: 'bons-commande-appro' }">
        Bons de commande
      </b-nav-item>
      <b-nav-item :to="{ name: 'bons-livraison-appro' }">
        Bons de livraison
      </b-nav-item>
    </CollapsibleMenuComponent>
    <!-- Menu collecte -->
    <CollapsibleMenuComponent
      v-show="$store.getters['auth/hasAccesCollecteModule']"
      icon="CollecteMenuIcon"
      title="Collecte"
      ref="menuCollecte"
      @show="closeMenusExcept('menuCollecte')"
    >
      <b-nav-item :to="{ name: 'reglements-campagne' }">
        R&egrave;glements de campagne
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters['auth/hasAccesCollecteEchantillons']"
        :to="{ name: 'analyses-moisson' }"
      >
        Echantillons moisson
      </b-nav-item>
      <b-nav-item :to="{ name: 'bons-apport' }">Bons d'apport</b-nav-item>
      <b-nav-item :to="{ name: 'contrats-apport' }">
        Suivi de la collecte
      </b-nav-item>
      <b-nav-item 
        v-if="$store.getters['auth/hasAccesCollecteEgalim']"
        :to="{ name: 'prix-fin-campagne' }"
      >
        Prix fin de campagne
      </b-nav-item>
    </CollapsibleMenuComponent>
    <!-- Menu administratif -->
    <CollapsibleMenuComponent
      v-show="$store.getters['auth/hasAccesAdministratifModule']"
      icon="AdministratifMenuIcon"
      title="Administratif"
      ref="menuAdministratif"
      @show="closeMenusExcept('menuAdministratif')"
    >
      <b-nav-item
        v-if="$store.getters['auth/hasAccesAdministratifCertificats']"
        :to="{ name: 'certificats' }"
      >
        Certificats Bio
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters['auth/hasAccesAdministratifReleveCompte']"
        :to="{ name: 'releve-compte' }"
      >
        Relev&eacute; de compte
      </b-nav-item>
      <b-nav-item
        v-if="$store.getters['auth/hasAccesAdministratifCapsoc']"
        :to="{ name: 'situation-capsoc' }"
      >
        Capital social
      </b-nav-item>
    </CollapsibleMenuComponent>
    <!-- Menu communication -->
    <CollapsibleMenuComponent
      v-show="$store.getters['auth/hasAccesCommunicationModule']"
      icon="BIconMegaphoneFill"
      title="Communication"
      ref="menuCommunication"
      @show="closeMenusExcept('menuCommunication')"
    >
      <b-nav-item :to="{ name: 'com-assistant' }">
        Nouvel envoi
      </b-nav-item>
      <b-nav-item :to="{ name: 'com-templates' }">Templates</b-nav-item>
      <b-nav-item :to="{ name: 'com-historique' }">Historique</b-nav-item>
    </CollapsibleMenuComponent>
    <b-nav-item
      v-if="$store.getters['auth/hasAccesReferentielModule']"
      :to="{ name: 'referentiel' }"
    >
      <b-icon-ui-checks></b-icon-ui-checks>&nbsp;
      R&eacute;f&eacute;rentiel
    </b-nav-item>
    <b-nav-item
      v-if="$store.getters['auth/hasAccesBackofficeModule']"
      :to="{ name: 'backoffice' }"
    >
      <b-icon-tools></b-icon-tools>&nbsp; Backoffice
    </b-nav-item>
  </b-nav>
</template>

<script>
import CollapsibleMenuComponent from "./CollapsibleMenuComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "MenuCommunComponent",
  components: { CollapsibleMenuComponent },
  data() {
    return {
      // Nom des routes gérées par chaque menu
      collapses: this.$store.state.structureMenu,
    };
  },
  computed: {
    recoltes() {
      return this.$store.state.ref.recoltes.map((r) => r.codeRecolte);
    },
    societe() {
      return this.$store.getters["societe"];
    },
    pages() {
      return this.$store.getters["strapi/pages"](this.societe.code)
        .filter((p) => !p.attributes.collectionObject || p.attributes.collectionObject.access(this.$store))
        .filter((p) => !p.attributes.categorielettre?.data || this.$store.getters['auth/hasAccesStrapiLettres'])
        .sort(UtilsService.sortByNumberProperty("attributes.ordre"));
    },
  },
  mounted() {
    this.openMenuActif();
    this.$watch(
      () => this.$route.path,
      () => this.openMenuActif()
    );
    this.$watch(
      () => this.$route.params,
      () => this.openMenuActif()
    );
  },
  methods: {
    openMenuActif() {
      for (const name in this.collapses) {
        let activeMenu = this.collapses[name].includes(this.$route.name);
        if (activeMenu) {
          this.$refs[name].show();
        } else {
          this.$refs[name].hide();
        }
      }
    },
    closeMenusExcept(except) {
      for (const name in this.collapses) {
        if (name !== except) {
          this.$refs[name].hide();
        }
      }
    },
  },
};
</script>
