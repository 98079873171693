import UtilsService, { SortDirection } from "../services/utils.service";
import StrapiService from "../services/strapi.service";

export const strapi = {
  namespaced: true,
  state: {
    pages: [],
    collections: [
      {
        code: "actualites",
        slug: "actualites",
        component: "StrapiActualiteCard",
        layout: "grid",
        cols: "auto",
        access: (store) => store.getters['auth/hasAccesStrapiActualites'],
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
          UtilsService.sortByStringProperty("attributes.publishedAt", SortDirection.DESC),
        ),
      },
      {
        code: "catalogues",
        slug: "catalogues",
        component: "StrapiCatalogueComponent",
        layout: "grid",
        cols: 12,
        access: (store) => store.getters['auth/hasAccesApproCatalogue'],
        publicationState: "preview",
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
        ),
      },
      {
        code: "contacts",
        slug: "equipes",
        component: "StrapiEquipeComponent",
        layout: "grid",
        cols: 12,
        access: (store) => store.getters['auth/hasAccesStrapiContacts'],
        sort: UtilsService.sortByNumberProperty("attributes.ordre"),
      },
      {
        code: "evenements",
        slug: "evenements",
        component: "StrapiAgendaComponent",
        layout: "unique",
        access: (store) => store.getters['auth/hasAccesStrapiActualites'],
      },
      {
        code: "questions",
        slug: "categories-questions",
        component: "StrapiCategorieQuestionComponent",
        layout: "grid",
        cols: 12,
        access: () => true, // Pas de contrôle d'accès pour la FAQ et le pied de page
        sort: UtilsService.sortByNumberProperty("attributes.ordre"),
      },
      {
        code: "reglements-campagne",
        slug: "reglements-campagne",
        component: "StrapiReglementCampagneComponent",
        layout: "grid",
        cols: 12,
        access: (store) => store.getters['auth/hasAccesCollecteModule'],
        sort: UtilsService.sortBy(
          UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
          UtilsService.sortByNumberProperty("attributes.ordre", SortDirection.ASC),
        ),
      },
      {
        code: "assemblees-generales",
        slug: "assemblees-generales",
        component: "StrapiAssembleeGeneraleComponent",
        layout: "grid",
        cols: 12,
        access: (store) => store.getters['auth/hasAccesAdministratifAgAdherent'],
        sort: UtilsService.sortByNumberProperty("attributes.campagne", SortDirection.DESC),
      },
    ],
    composants: [
      { code: "blocs.ligne", component: "StrapiLigneComponent", },
      { code: "blocs.colonne", component: "StrapiColonneComponent", },
      { code: "blocs.carrousel", component: "StrapiCarrouselComponent", },
      { code: "blocs.pieces-jointes", component: "StrapiFichiersComponent", },
      {
        code: "tableau.actualites",
        component: "ActualitesWidget",
        defaultIcon: "ActualitesWidgetIcon",
        access: (store) => store.getters['auth/hasAccesStrapiActualites'],
      },
      {
        code: "tableau.compta",
        component: "ComptaWidget",
        defaultIcon: "ComptaWidgetIcon",
        access: (store) => store.getters['auth/hasAccesAdministratifReleveCompte'],
      },
      {
        code: "tableau.contacts",
        component: "ContactsWidget",
        defaultIcon: "ContactsWidgetIcon",
        access: (store) => store.getters['auth/hasAccesStrapiContacts'],
      },
      {
        code: "tableau.declarations",
        component: "DeclarationsWidget",
        defaultIcon: "ComptaWidgetIcon",
        access: (store) => store.getters['auth/hasAccesDeclarationsModule'],
      },
      {
        code: "tableau.documents",
        component: "DocumentsWidget",
        defaultIcon: "DocumentsWidgetIcon",
        access: () => true, // Filtrage du contenu par le composant lui-même
      },
      {
        code: "tableau.indicateurs",
        component: "IndicateursWidget",
        defaultIcon: "IndicateursWidgetIcon",
        access: (store) => store.getters['auth/hasAccesCollecteModule'],
      },
      {
        code: "tableau.libre",
        component: "LibreWidget",
        defaultIcon: "LibreWidgetIcon",
        access: () => true, // FIXME Envisager un filtrage d'audience ? Par type tiers ?
      },
    ],
  },
  getters: {
    // Accesseurs standards
    page: (state) => (id) =>
      state.pages.find((p) => p.id == id),
    collection: (state) => (code) =>
      state.collections.find((c) => c.code === code),
    composant: (state) => (code) =>
      state.composants.find((c) => c.code === code),
    // Listes filtrées
    pages: (state) => (codeSociete) =>
      state.pages.filter((p) => p.attributes.societe?.data?.attributes.codetiers === codeSociete),
  },
  actions: {
    async loadPages({ commit, getters, rootGetters }) {
      const codesSociete = rootGetters["auth/societes"];
      try {
        const response = await StrapiService.getPagesBySocietes(codesSociete);
        const pages = response.data.data;
        pages.forEach(page => {
          const collection = getters.collection(page.attributes.collection);
          page.attributes.collectionObject = collection;
        });
        commit("setPages", pages);
      } catch (e) {
        commit("setPages", []);
      }
    },
    clear({ commit }) {
      commit("setPages", []);
    }
  },
  mutations: {
    setPages(state, pages) {
      state.pages = pages;
    },
  },
};
