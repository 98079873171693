<template>
  <b-container fluid class="d-block d-md-none menu-mobile">
    <b-row align-v="center" style="height: 60px">
      <b-col cols="auto">
        <strong>
          <span class="h1">
            <b-icon-list v-b-toggle.sidebar-mobile role="button"></b-icon-list>
          </span>
        </strong>
      </b-col>
      <b-col class="text-center">
        <b-link class="text-light" :to="{ name: 'exploitations' }">
          <strong>
            {{ exploitationCourante.raisonSociale }}
          </strong>
        </b-link>
      </b-col>
      <b-col cols="auto">
        <b-link :to="{ name: 'compte' }">
          <span class="h2">
            <AccountMenuIcon></AccountMenuIcon>
          </span>
        </b-link>
      </b-col>
    </b-row>
    <b-sidebar
      id="sidebar-mobile"
      :title="societe.libelle"
      text-variant="light"
      bg-variant="primary"
      shadow
    >
      <b-navbar class="d-flex flex-column" style="width: 100%">
        <MenuHeaderComponent></MenuHeaderComponent>
        <MenuCommunComponent></MenuCommunComponent>
        <div class="w-100 mt-1 mb-1" style="border-top: 1px solid white"></div>
        <b-nav small vertical>
          <b-nav-item :to="{ name: 'faq' }">Foire aux questions</b-nav-item>
          <b-nav-item :to="{ name: 'changelog' }">
            Historique des changements
          </b-nav-item>
          <b-nav-item :to="{ name: 'ecoconception' }">
            Conception/r&eacute;alisation
          </b-nav-item>
          <b-nav-item :to="{ name: 'mentions' }">
            Mentions l&eacute;gales
          </b-nav-item>
          <b-nav-text class="w-100 text-center">
            <em>
              &copy; 2022 Union Fermes Bio - Contact :
              <b-link href="mailto:idees_extranet@fermesbio.fr">
                idees_extranet@fermesbio.fr
              </b-link>
            </em>
          </b-nav-text>
        </b-nav>
      </b-navbar>
    </b-sidebar>
  </b-container>
</template>

<script>
import MenuHeaderComponent from "./MenuHeaderComponent.vue";
import MenuCommunComponent from "./MenuCommunComponent.vue";
import AccountMenuIcon from "../icons/AccountMenuIcon.vue";
export default {
  name: "MenuMobileComponent",
  components: { MenuHeaderComponent, MenuCommunComponent, AccountMenuIcon, },
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societe() {
      return this.$store.getters["societe"];
    },
  },
};
</script>
